@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --black: #000;
  --white: #fff;
  --white1: rgba(255, 255, 255, 0.1);
  --white2: rgba(255, 255, 255, 0.2);
  --white3: rgba(255, 255, 255, 0.3);
  --white4: rgba(255, 255, 255, 0.4);
  --white5: rgba(255, 255, 255, 0.5);
  --white6: rgba(255, 255, 255, 0.6);
  --white7: rgba(255, 255, 255, 0.7);
  --white8: rgba(255, 255, 255, 0.8);
  --white9: rgba(255, 255, 255, 0.9);
  --yellowNVL: #fcb746;
  --purpleNVL: #5048e2;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

 /* .al-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
} */
 .al-box-con {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white);
}
.al-box-con .flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
img {
  width: 100%;
  /* height: 250px; */
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
h3 {
  font-size: 17.5px;
  font-weight: 600;
  color: var(--zinc-700);
  margin: 15px 0;
}
 h4 {
  font-size: 15px;
  font-weight: 500;
  color: var(--zinc-700);
  width: -moz-max-content;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}
 h4 span {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
  padding: 8.5px 17.5px;
  background-color: var(--zinc-100);
  color: var(--zinc-700);
  border-radius: 7px;
}
 h4 div {
  width: 40px;
  height: 40px;
  background-color: var(--zinc-100);
  color: var(--zinc-700);
  border-radius: 7px;
  font-size: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 select {
  min-width: 190px;
  border-radius: 7px;
  padding: 8.5px 17.5px;
  border: 1px solid var(--zinc-300);
  font-size: 15px;
  color: var(--zinc-700);
}
 .al-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white1);
  z-index: 99999;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
}
 .al-modal .al-update {
  width: 100%;
  max-width: 500px;
  padding: 25px;
  border-radius: 15px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  box-shadow: 0 0 15px 25px rgba(0, 0, 0, 0.1);
}
 .al-modal .al-update .al-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 .al-modal .al-update .al-head span {
  transition: 0.35s;
}
 .al-modal .al-update .al-head span svg {
  color: var(--zinc-500);
}
 .al-modal .al-update .al-head span:hover svg {
  color: var(--zinc-700);
}
 .al-modal .al-update .input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
 .al-modal .al-update .input-box input {
  min-width: 250px;
  width: 100%;
  border-radius: 7px;
  padding: 8.5px 17.5px;
  border: 1px solid var(--zinc-300);
  font-size: 15px;
  color: var(--zinc-700);
}
 .al-modal .al-update .input-box input label {
  font-size: 15px;
  margin: 0 50px 0 0;
  display: block;
}
 .al-modal .al-update .input-box input:disabled {
  border: 0;
  background-color: var(--zinc-200);
}
 .al-modal .al-update .checkbox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
 .al-modal .al-update .checkbox label {
  font-size: 13.5px;
  line-height: 17px;
}
 .al-modal .al-update .checkbox input {
  zoom: 1.25;
  margin: 2px 0 0 0;
  accent-color: var(--purpleNVL);
}
 .al-modal .al-update button {
  width: 100%;
}

button {
  outline: 0;
  border: 0;
  padding: 8.5px 17.5px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 7px;
  font-size: 15px;
  font-weight: 500;
  transition: 0.35s;
  cursor: pointer;
  color: #fff;
  background-color: var(--purpleNVL);
}
/* button:hover {
  background-color: rgba(80, 72, 226, 0.15);
  color: var(--purpleNVL);
}# sourceMappingURL=AffiliateLinks.css.map */
