

.sep-connection-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.sep-connection-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.sep-connection-scroll::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 3px;
}
.sep-connection-popup {
    background: rgb(0 0 0 / 65%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sep-connection-popup-inner {
    background: #fff;
    border-radius: 20px;
    width: calc(100% - 40px);
    max-width: 975px;
    position: relative;
    max-height: 85vh;
    overflow: hidden;
}
.sep-connection-content {
    overflow: auto;
    height: auto;
    max-height: 85vh;
}
.sep-connection-close-btn {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #D7D7D7;
    background: #f6f6f6;
    cursor: pointer;
    z-index: 1;
    transition: .15s;
    opacity: 1;
}
.sep-connection-popup-inner::after {
    content: "";
    width: 450px;
    height: 450px;
    background: #1877F2;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(1.5) translateX(-20%);
    position: absolute;
    border-radius: 50%;
}
.sep-connection-text {
    position: relative;
    z-index: 12;
    display: grid;
    grid-template-columns: 44% 49%;
    justify-content: space-between;
    align-items: center;
    padding: 8%;
}
.sep-connection-text-left {
    padding-bottom: 35px;
}
.sep-connection-text-left>span {
    font-size: clamp(36px, 3.75vw, 48px);
    display: block;
    line-height: 1.05;
    font-weight: 600;
    color: #fff;
    margin-top: 16px;
}
.sep-connection-text-left img {
    width: 20%;
}
.sep-connection-btnBox {
    background: #f5f5f5;
    padding: 70px 50px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.sep-connection-text-right button {
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 22px;
    color: #fff;
    font-weight: 500;
    padding: 12px 40px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 35%);
    transition: .25s;
    cursor: pointer;
    max-width: 240px;
}
.sep-connection-fb-btn {
    background: #1877F2;
}
.sep-connection-ig-btn {
    background: linear-gradient(90deg, #FCB850, #F1203F, #BD289C, #693CCB);
}
.sep-connection-text-right button:hover {
    box-shadow: 0 4px 10px rgb(0 0 0 / 50%);
}


@media (min-width: 1650px) {
    .sep-connection-popup-inner {
        zoom: 1.2;
    }
}


@media (min-width: 1920px) {
    .sep-connection-popup-inner {
        zoom: 1.35;
    }
}

@media (min-width: 2450px) {
    .sep-connection-popup-inner {
        zoom: 1.5;
    }
}

@media (min-width: 3000px) {
    .sep-connection-popup-inner {
        zoom: 1.65;
    }
}


@media (max-width: 991px) {
    .sep-connection-text {
        padding: 35px;
    }
}

@media (max-width: 768px) {
    .sep-connection-text {
        grid-template-columns: 100%;
        padding: 35px;
        gap: 36px;
    }
    .sep-connection-btnBox {
        padding: 35px;
        width: max-content;
        margin: 0 auto;
    }
    .sep-connection-text-left {
        text-align: center;
    }
    .sep-connection-popup-inner::after {
        width: 100%;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateY(-72%) scale(1.5) translateX(-33%);
    }
}

