.connect-account-btn {
    outline: 3px solid rgb(28 93 205 / 30%);
    border-radius: 10px;
    padding: 15px 30px;
    background: #2C73FF;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    transition: 0.2s;
    min-width: 210px;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
}
.connect-wraper {
    background-image: url(/./public/assets/images/connect-bg-img.png);
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    min-height: 400px;
}