/* .MuiTextField-root { */
.css-1e7mi5c-MuiDataGrid-root .MuiTextField-root {
  width: 320px !important;
  position: absolute !important;
  top: -36px !important;
  left: 0px !important;
}
.nova-box .MuiInputBase-root::before {
  border: 1px solid #e0e1e3 !important;
  height: 34px !important;
  border-radius: 6px;
}
.nova-box .MuiInputBase-root {
  padding: 2px 15px !important;
}
.btn-group-friends-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  margin-left: auto;
  gap: 8px;
}

.nova-gender-header .MuiDataGrid-columnHeaderTitleContainer,
.nova-lives-header .MuiDataGrid-columnHeaderTitleContainer,
.nova-lives-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.nova-gender-header .MuiDataGrid-iconButtonContainer,
.nova-lives-header .MuiDataGrid-iconButtonContainer,
.nova-lives-header .MuiDataGrid-iconButtonContainer {
  width: auto;
}
.nova-lives-custom-div {
  text-align: center;
}
.nova-lives-custom-div * {
  white-space: break-spaces;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  padding: 0 6px;
}
.nova-table-ctm .MuiInputBase-formControl svg {
  color: #2c73ff;
}
.nova-table-ctm .MuiInputBase-formControl input {
  border-left: 1px solid #e0e1e3;
  margin-left: 8px;
  padding-left: 10px;
  font-family: 'DM Sans';
}