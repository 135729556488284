* {
  box-sizing: border-box;
}
select:focus,
select:focus-visible {
  outline: none;
}
body {
  padding: 0;
  margin: 0;
  background: #f6f6f9;
  font-family: "DM Sans", sans-serif;
  color: #170f49;
}
img,
input,
select,
textarea {
  width: 100%;
  max-width: 100%;
  font-family: "DM Sans", sans-serif;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
.tutorial-btn {
  background: #fa0001;
  color: #fff;
  border: none;
  padding: 10px 16px 10px 54px;
  border-radius: 30px;
  position: relative;
  font-weight: 700;
  cursor: pointer;
}
.tutorial-btn-icon span {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}
.tutorial-btn-icon {
  position: absolute;
  left: 0;
  background: #fa0001;
  width: 44px;
  height: 44px;
  top: -6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-title {
  margin: 0;
  font-size: 26px;
  text-transform: capitalize;
  color: #170f49;
}
.step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0 24px;
}
.shadow-card {
  box-shadow: 0 5px 15px rgb(8 15 52 / 7%);
  background: #fff;
  border-radius: 24px;
  padding: 30px 20px;
  min-height: 70vh;
}
.step-fill {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;
}
.step-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: relative;
  z-index: 1;
}
.step-circle {
  width: 30px;
  height: 30px;
  background: #ddd9ff;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  color: #6f6c90;
  outline: 7px solid #fff;
}
.step-text {
  font-size: 14px;
  color: #170f49;
  font-weight: 700;
}
.step-fill::before {
  content: "";
  width: calc(100% - 80px);
  background: #ddd9ff;
  position: absolute;
  height: 6px;
  left: 36px;
  z-index: 1;
  top: 12px;
}
.fill-step .step-circle {
  background: #2C73FF;
  color: #fff;
}
.bordered-btn {
  color: #2C73FF;
  border: none;
  border-radius: 10px;
  padding: 6px 32px;
  font-size: 14px;
  font-weight: 500;
  background: rgb(44 115 255 / 20%);
  outline: 2px solid rgb(28 93 205 / 100%);
  text-transform: uppercase;
  cursor: pointer;
  min-height: 40px;
}
.bordered-btn-bg {
  background: #2C73FF;
  color: #fff;
  outline: 2px solid rgb(44 115 255 / 20%);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.col-2-video {
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 36px;
  align-items: center;
  min-height: 100vh;
}
.vertical-step-text a {
  width: 100%;
  max-width: 200px;
}
.video-wrap {
  position: relative;
}
.play-video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.play-video svg {
  width: 55px;
}
.bordered-cards {
  border: 1px solid #d9dbe9;
  border-radius: 16px;
  padding: 12px 16px 5px;
  height: calc(100% - 24px);
}
.cards-title {
  font-size: 20px;
  font-weight: 900;
  padding-left: 16px;
  display: inline-block;
}
.col-2-video p {
  max-width: 300px;
  margin: 12px auto 36px;
}
.gray-btn-bg {
  background: rgb(94 59 183 / 10%);
  outline: 2px solid rgb(94 59 183 / 10%);
  display: flex;
  align-items: center;
  gap: 12px;
  color: #170f49;
  white-space: nowrap;
  padding: 5px 14px;
  text-transform: capitalize;
}
.vertical-step-text a {
  width: 100%;
  max-width: 200px;
}
.social-icon-bg {
  width: 32px;
  display: flex;
  height: 32px;
  background: rgb(23 88 180 / 20%);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 7px;
  box-sizing: border-box;
}
.social-btn-box {
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  max-width: 450px;
}
.social-icon-bg {
  width: 32px;
  display: flex;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;
}
.social-icon-facebook {
  background: rgb(23 88 180 / 20%);
}
.social-icon-twitter {
  background: rgb(12 189 227 / 20%);
}
.social-icon-youtube {
  background: rgb(255 0 0 / 20%);
}
.step-text-head {
  display: flex;
  margin-bottom: 12px;
}
.vertical-step-text {
  padding-left: 46px;
}

.step-vertial > div {
  padding-bottom: 60px;
  position: relative;
}
.step-text-head span.cards-title {
  font-weight: 600;
}
.flag-img {
  width: 30px;
  height: 20px;
  object-fit: cover;
  border-radius: 5px;
}
.vertical-step-01 > div::before {
  content: "";
  width: 6px;
  background: #ddd9ff;
  position: absolute;
  height: calc(100% - 50px);
  left: 12px;
  z-index: 1;
  top: 40px;
}
.vertical-step-01 > div::before,
.vertical-step-02 > div::before {
  content: "";
  width: 6px;
  background: #ddd9ff;
  position: absolute;
  height: calc(100% - 50px);
  left: 12px;
  z-index: 1;
  top: 40px;
}
.vertical-step-01 > div::before {
  background: #2C73FF;
}
.col-2-video .shadow-card {
  padding: 50px 35px 0px;
}
.text-center {
  text-align: center;
  display: block;
}

@media (max-width: 767px) {
  .col-2-video {
    grid-template-columns: 1fr;
  }
  .social-btn-box {
    grid-template-columns: 1fr;
  }
  .col-2-video .shadow-card {
    padding: 35px 20px 0;
  }
  .vertical-step-text a {
    width: 100%;
    max-width: 195px;
    white-space: nowrap;
  }
}
