@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --black: #000;
  --white: #fff;
  --white1: rgba(255, 255, 255, 0.1);
  --white2: rgba(255, 255, 255, 0.2);
  --white3: rgba(255, 255, 255, 0.3);
  --white4: rgba(255, 255, 255, 0.4);
  --white5: rgba(255, 255, 255, 0.5);
  --white6: rgba(255, 255, 255, 0.6);
  --white7: rgba(255, 255, 255, 0.7);
  --white8: rgba(255, 255, 255, 0.8);
  --white9: rgba(255, 255, 255, 0.9);
  --yellowNVL: rgb(252, 183, 70);
  --purpleNVL: #6b21a8;
  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;
  --zinc-950: #09090b;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.btn-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 15px 0;
}
.btn-purple {
  outline: 0;
  border: 0;
  background-color: var(--purpleNVL);
  padding: 8.5px;
  width: 100%;
  border-radius: 7px;
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.35s;
  border: 2px solid rgba(107, 33, 168, 0.5);
  cursor: pointer;
  &:hover {
    background-color: rgba(107, 33, 168, 0.85);
  }
}
.btn-purple-light {
  outline: 0;
  border: 0;
  background-color: var(--purpleNVL);
  padding: 8.5px;
  width: 100%;
  border-radius: 7px;
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.35s;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  &:hover {
    background-color: rgba(107, 33, 168, 0.85);
    color: var(--white);
  }
}
.btn-purple-outlined {
  outline: 0;
  border: 0;
  background-color: #fff;
  padding: 8.5px;
  width: 100%;
  border-radius: 7px;
  color: var(--purpleNVL);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.35s;
  border: 2px solid rgba(107, 33, 168, 0.5);
  cursor: pointer;
  &:hover {
    background-color: rgba(107, 33, 168, 0.1);
  }
}
.affiliate {
  width: 100%;
  min-height: 100vh;
  padding: 50px;
  h2 {
    font-size: 25px;
    line-height: 20px;
    font-weight: 500;
    color: var(--zinc-700);
    margin: 0 0 10px 0;
  }
  h3 {
    font-size: 17.5px;
    line-height: 15px;
    font-weight: 500;
    color: var(--zinc-700);
    margin: 0 0 10px 0;
  }
  p {
    color: var(--zinc-600);
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .as-btn-grid button:last-child {
    grid-column: 1/-1;
  }
  .affiliate-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    .grid-box-1 {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column: 1/-1;
      grid-gap: 15px;
      height: max-content;
    }
    .grid-box-2 {
      width: 100%;
      display: grid;
      grid-template-columns: 3.2fr 2fr;
      grid-column: 1/-1;
      grid-gap: 15px;
      height: max-content;
      .affiliate-stepper {
        height: max-content;
        position: sticky;
        top: 75px;
      }
      .total-earnings {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: max-content;
        background-color: var(--zinc-700);
        & > span {
          font-size: 30px;
          font-weight: 400;
          color: var(--zinc-400);
        }
        h6 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 25px;
          color: var(--zinc-200);
          font-weight: 500;
          span {
            font-size: 12.5px;
            font-weight: 400;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: var(--zinc-400);
            margin: 0 0 5px 0;
          }
        }
        h5 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 25px;
          color: var(--yellowNVL);
          font-weight: 500;
          span {
            font-size: 12.5px;
            font-weight: 400;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: var(--yellowNVL);
            margin: 0 0 5px 0;
          }
        }
      }
      .sales-bonus {
        width: 100%;
        margin: 15px 0 0 0;
        .current-payout {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0 25px;
          h3 {
            grid-column: 1/-1;
          }
        }
        .active-sales {
          width: 100%;
          padding: 0 0 25px 0;
          .as-prog {
            width: 100%;
            height: 10px;
            background-color: rgba(107, 33, 168, 0.1);
            border-radius: 1000px;
            div {
              width: 35%;
              border-radius: 1000px 0 0 1000px;
              height: 10px;
              background-color: rgba(107, 33, 168, 0.65);
              position: relative;
              &::before {
                content: "";
                width: 1px;
                background-color: rgba(107, 33, 168, 0.65);
                height: 15px;
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translate(0, 50%);
              }
              span {
                font-size: 13.5px;
                font-weight: 600;
                position: absolute;
                right: 0;
                bottom: 0;
                color: var(--zinc-700);
                transform: translate(50%, 125%);
                background-color: rgba(107, 33, 168, 0.65);
                padding: 2px;
                color: var(--white);
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .medals-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
      padding: 100px 0 0 0;
      .medal-card {
        position: relative;
        padding: 25px;
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        background-color: var(--zinc-100);
        /* &:nth-child(1) {
          background-color: RGBA(0, 201, 169, 0.1);
          h3 {
            color: RGBA(0, 201, 169, 1);
            font-weight: 600;
            padding: 75px 0 5px 0;
          }
        }
        &:nth-child(2) {
          background-color: RGBA(248, 156, 64, 0.1);
          h3 {
            color: RGBA(248, 156, 64, 1);
            font-weight: 600;
            padding: 75px 0 5px 0;
          }
        }
        &:nth-child(3) {
          background-color: RGBA(231, 51, 80, 0.1);
          h3 {
            color: RGBA(231, 51, 80, 1);
            font-weight: 600;
            padding: 75px 0 5px 0;
          }
        } */
        img {
          width: 150px;
          // height: 150px;
          // border-radius: 50%;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        h3 {
          padding: 75px 0 5px 0;
          color: var(--zinc-700);
        }
      }
    }
    .current-status {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        img {
          width: 20px;
          object-fit: contain;
          margin: 0 0 0 10px;
        }
      }
      span {
        font-size: 15px;
        color: var(--zinc-600);
      }
    }
    .new-sales-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      svg {
        width: 100%;
        height: max-content;
        object-fit: contain;
        color: var(--zinc-200);
        &.new {
          color: rgba(107, 33, 168, 0.85);
        }
      }
    }
    .willbe-paid-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
      .timer {
        background-color: transparent;
      }
      .willbe-paid-card {
        width: 100%;
        padding: 20px;
        background-color: var(--zinc-50);
        position: relative;
        border-radius: 10px;
        .flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 50px;
            position: absolute;
            top: 0;
            left: 20px;
            transform: translate(0, -50%);
          }
        }
        h3 {
          font-weight: 600;
          display: flex;
          align-items: baseline;
          font-size: 15px;
          color: var(--zinc-600);
          margin: 5px 0;
          span {
            font-size: 15px;
            color: var(--zinc-400);
            font-weight: 500;
            margin: 0 0 0 5px;
          }
        }
        .timer .timer-box h5 {
          font-size: 17.5px;
        }
        h4 {
          font-weight: 600;
          font-size: 20px;
          color: var(--zinc-700);
          margin: 0;
          text-align: right;
          width: 100%;
        }
        /* &:nth-child(1) h3 {
          color: RGBA(0, 201, 169, 1);
        }
        &:nth-child(2) h3 {
          color: RGBA(248, 156, 64, 1);
        }
        &:nth-child(3) h3 {
          color: RGBA(231, 51, 80, 1);
        } */
      }
    }
  }

  .css-xbqlz2-MuiSvgIcon-root-MuiStepIcon-root {
    width: 2rem;
    height: 2rem;
  }
  .css-1pe7n21-MuiStepConnector-root,
  .css-14yr603-MuiStepContent-root {
    margin-left: 17px;
  }
  .css-162vrrj-MuiStepLabel-label {
    font-family: "Poppins", sans-serif;
    font-size: 17.5px;
    font-weight: 600 !important;
  }
  .css-xbqlz2-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--purpleNVL) !important;
  }
  .statistics {
    width: 100%;
    th:nth-child(1),
    td:nth-child(1) {
      width: 60px;
      cursor: pointer;
    }
    .box-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        margin: 0;
      }
      .btn-grid {
        width: max-content;
        margin: 0;

        button {
          padding: 10px 25px;
        }
      }
    }
    .newcus-box {
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 10px 0 0;
      }
      p {
        font-weight: 500;
        color: var(--zinc-600);
        line-height: 12.5px;
      }
      span {
        font-size: 12.5px;
        color: var(--zinc-400);
      }
    }
  }
}
.eb-box {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  background-color: rgba(80, 72, 226, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h6 {
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    color: var(--zinc-400);
    color: var(--purpleNVL);
  }
  h4 {
    font-size: 30px;
    line-height: 32.5px;
    font-weight: 600;
    color: var(--zinc-600);
    color: var(--purpleNVL);
  }
  &.lifetime-earnings {
    background-color: var(--zinc-700);
    h6,
    h4 {
      color: var(--yellowNVL);
    }
  }
}
.spacer {
  width: 100%;
  height: 25px;
}
.NVL-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    border-radius: 10px 10px 0 0;
  }
  th {
    border: 1px solid var(--zinc-300);
  }
  tr,
  td {
    border: 1px solid var(--zinc-200);
  }
  th {
    padding: 10px 15px;
    background-color: rgba(107, 33, 168, 0.15);
    color: var(--purpleNVL);
    font-weight: 600;
    font-size: 15px;
  }
  td {
    padding: 10px 15px;
    color: var(--zinc-600);
    font-weight: 400;
    font-size: 15px;
  }
}
.grid-box {
  width: 100%;
  padding: 25px;
  border-radius: 15px;
  background-color: #fff;
}
.timer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  h4 {
    font-size: 15px;
    line-height: 17.5px;
    color: var(--zinc-700);
    margin: 0 0 10px 0;
  }
  .timer-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-size: 25px;
      font-weight: 400;
      color: var(--zinc-700);
    }
    h5 {
      display: flex;
      align-items: baseline;
      justify-content: center;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      color: var(--zinc-700);
      span {
        font-size: 11.5px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--zinc-400);
        margin: 0 0 0 5px;
      }
    }
  }
}

.btn-grid .monthly-earnings {
  grid-column: 1/-1;
  button {
    width: max-content;
  }
}
.spacer {
  width: 100%;
  height: 15px;
}
