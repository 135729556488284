.content-box ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}

.content-box ::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;

  border-radius: 10px;
}

.content-box ::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
}

.item.dropArea {
  color: white !important;
  position: relative;
  cursor: grabbing;
  transform: scale(1.05);
  user-select: none;
}

.item.dropArea::before {
  content: "Drop Here";
  color: #687bf7;
  font-size: 0.5em;
  text-transform: uppercase;
  padding: 10px;
  width: 100%;
  height: 100%;
  border: 2px dashed #687bf7 !important;
  border-radius: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item.dropArea.dropArea .group-wraper-inner {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}

/*.container {
  max-width: 1070px;
  width: 100%;
  margin: 0 auto;
}
*/
.content-box {
  max-width: 100% !important;
}

input:focus,
select:focus {
  outline: none;
}

img,
input,
select,
textarea {
  width: 100%;
  max-width: 100%;
  font-family: "DM Sans", sans-serif;
}

.serach-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 239px;
  border: 1px solid #e0e1e3;
  border-radius: 5px;
}

.serach-form input {
  border: none;
  padding: 5px;
  color: #8a8c8d;
}

.search-btn {
  border: none;
  display: inline-flex;
  padding: 2px 7px 2px 7px;
  border-right: 1px solid #e0e1e3;
  background: transparent;
  margin: 3px;
  cursor: pointer;
  font-size: 14px;
}

.search-btn svg {
  width: 18px;
}

.serach-form svg path {
  fill: #2c73ff;
}

.shadow-wraper {
  box-shadow: 0 5px 15px rgb(8 15 52 / 7%);
  background: #fff;
  padding: 15px 30px 10px;
  border-radius: 10px;
  min-height: calc(100vh - 210px);
}

.content-box {
  max-width: 1070px;
  margin: 20px auto 8px;
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.steps-number {
  width: 26px;
  height: 26px;
  background: #e0eafb;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  outline: 7px solid #fff;
  margin: 0 auto;
}

.steps-item span {
  font-size: 12px;
  font-weight: 600;
  color: #170f49;
  position: relative;
  z-index: 2;
  font-family: 'DM Sans';
}

.steps-item {
  text-align: center;
}

.steps-bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10%;
  position: relative;
  margin-bottom: 24px;
  max-width: 1260px;
  margin: 0 auto 12px;
}

.progress-bar {
  position: absolute;
  width: calc(100% - 180px);
  background: #e0eafb;
  display: block;
  height: 4px;
  left: 90px;
  border-radius: 6px;
  top: 12px;
}

.progress-bar::after {
  position: absolute;
  content: "";
  background: #2C73FF;
  display: block;
  height: 4px;
  left: 0;
  border-radius: 6px;
  top: 0px;
  z-index: 1;
}

.steps-bar .progress-bar::after {
  width: 33%;
}

.step-done-01 .progress-bar::after {
  width: 66%;
}

.step-done-02 .progress-bar::after,
.step-done-03 .progress-bar::after {
  width: 99%;
}
.step-done-04 .progress-bar::after {
  width: 99%;
}

.steps-bar .steps-item:nth-child(02) .steps-number {
  background: #2C73FF;
  color: #fff;
}

.step-done-01 .steps-item:nth-child(02) .steps-number,
.step-done-01 .steps-item:nth-child(03) .steps-number {
  background: #2C73FF;
  color: #fff;
}

.step-done-02 .steps-item:nth-child(02) .steps-number,
.step-done-02 .steps-item:nth-child(03) .steps-number,
.step-done-02 .steps-item:nth-child(04) .steps-number {
  background: #2C73FF;
  color: #fff;
}

.step-done-03 .steps-item:nth-child(02) .steps-number,
.step-done-03 .steps-item:nth-child(03) .steps-number,
.step-done-03 .steps-item:nth-child(04) .steps-number,
.step-done-03 .steps-item:nth-child(05) .steps-number {
  background: #2C73FF;
  color: #fff;
}

.col-2-1 {
  display: grid;
  grid-template-columns: calc(73% - 10px) calc(27% - 10px);
  gap: 20px;
}


.col_1 .message-right {
  height: calc(100vh - 346px);
  overflow-y: auto;
}

.content-box .group-wraper {
  max-height: calc(100vh - 345px);
  overflow-y: auto;
}

.group-items {
  background: rgb(94 59 183 / 10%);
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}

.content-box .group-wraper .group-items {
  min-height: 95px;
}

.group-name {
  font-size: 13px;
  font-weight: 500;
  word-break: break-word;
  font-family: 'DM Sans';
}

.group-footer {
  background: #bdcbe8;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  align-items: center;
  border-radius: 0 0 8px 8px;
}

.group-wraper-inner {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;
  height: 100%;
  background: rgb(28 93 205 / 10%);
  /* max-width: 250px; */
  max-height: 125px;
  border-radius: 8px;
}
.group-wraper > div[draggable="true"] {
  padding: 2px !important;
  width: 100%;
}
.group-wraper > div[draggable="true"] .group-name svg {
  width: 20px;
  height: 18px;
}
.group-wraper > div[draggable="true"] .group-name {
  display: flex;
  gap: 8px;
  min-height: 60px;
}
.group-name {
  padding: 10px;
}
input[type="radio"]:checked + .group-wraper-inner .group-name svg path {
  fill: #fff;
}

.no-bdr {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-bdr svg {
  width: 15px;
  height: 15px;
}

.group-footer>span {
  font-size: 13px;
  font-weight: 500;
  font-family: 'DM Sans';
  text-transform: capitalize;
}

.group-items input[type="radio"] {
  display: none;
}
.message-right label.group-items .group-wraper-right {
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.message-right label.group-items .group-wraper-right span {
  font-weight: 400;
}
.group-wraper-right {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #e8eefa;
  border-radius: 3px;
  margin-bottom: 12px;
  padding: 5px 12px;
  min-height: 40px;
  transition: .15s;
}
.message-right button.MuiButtonBase-root {
  max-width: 100%;
  background: #2c73ff;
  width: 100%;
  color: #fff;
  letter-spacing: 0;
  margin: 12px 0;
  border-radius: 8px;
}
.group-wraper-right .group-name {
  padding: 0;
  font-size: 14px;
}

.content-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title-18 {
  font-size: 18px;
  font-weight: 600;
  font-family: 'DM Sans';
}

.col_1 .title-18 {
  display: block;
  text-align: center;
  margin: 0 0 16px;
}

.group-member {
  background: #2C73FF;
  color: #fff;
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 20px;
  padding: 1px 5px;
  min-width: 36px;
  text-align: center;
  border: 1px solid rgb(94 59 183 / 10%);
}

.group-right-footer {
  display: flex;
  align-items: center;
  gap: 6px;
}

input[type="radio"]:checked + .group-wraper-inner {
  background: #2C73FF;
  color: #fff;
}

input[type="radio"]:checked + .group-wraper-inner {
  background: #2C73FF;
  color: #fff;
}

input[type="radio"]:checked + .group-wraper-inner .group-footer {
  background: #2875fa;
}

input[type="radio"]:checked + .group-wraper-inner .group-footer svg path {
  fill: #fff;
}

.message-right input[type="radio"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

.message-right input[type="radio"]:checked + .group-wraper-right svg path {
  fill: #fff;
}

.message-right input[name="gender"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

.message-right input[name="gender"]:checked + .group-wraper-right svg path {
  fill: #fff;
}

.message-right input[name="keywords"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[name="action"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[name="request"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[name="interval"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}
input[name="stratagy"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff !important;
}
input[name="stratagy"]:checked + .group-wraper-right>span {
  color: #ffffff;
}

input[name="lunch"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[name="convo"]:checked + .group-wraper-right  {
  background: #2C73FF;
  color: #fff;
}

input[name="lunch-from"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[name="messages"]:checked + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}

input[type="radio"]:checked + .group-wraper-inner .group-member {
  background: #2C73FF;
  border: 1px solid #fff;
}

.tutorial-btn {
  background: #fa0001;
  color: #fff;
  border: none;
  padding: 8px 16px 8px 56px !important;
  border-radius: 30px;
  position: relative;
  font-weight: 600 !important;
  cursor: pointer;
  font-size: 12px;
}

.tutorial-btn-icon {
  position: absolute;
  left: 0;
  background: #fa0001;
  width: 45px;
  height: 45px;
  top: -7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgb(0 0 0 / 25%);
}

.tutorial-btn-icon span {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%) !important;
}

form.serach-form {
  max-width: 250px;
}

.content-title-box form.serach-form {
  max-width: 250px !important;
}

.title-21 {
  font-size: 20px;
  font-weight: 600;
  color: #170f49;
}

.step-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 0px;
}

.bordered-btn {
  color: #2C73FF;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
  background: rgb(94 59 183 / 20%);
  outline: 2px solid rgb(28 93 205 / 100%);
  text-transform: uppercase;
  cursor: pointer;
}

.bordered-btn-bg {
  background: #2C73FF;
  color: #fff;
  outline: 2px solid rgb(94 59 183 / 30%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.step-btn-group .bordered-btn.bordered-btn-bg {
  margin: 0;
}

.bordered-cards {
  border: 1px solid #d9dbe9;
  border-radius: 16px;
  padding: 16px;
  height: calc(100% - 24px);
  max-width: 420px;
  margin: 4% auto;
}

.welcome-img {
  width: 90px;
  margin: 0 auto;
  display: block;
}
.gredient-title {
  background: linear-gradient(to right, #2cffb8 0%, #679aff 30%, #4b86fb 60%, #2cffb8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(20px, 3vw, 25px);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  display: block;
  margin-bottom: 8px;
}

.gray-text {
  color: #828282;
  text-align: center;
  margin-bottom: 14px;
  line-height: 1.3;
}

.bordered-btn-bg {
  background: #2C73FF;
  color: #fff;
  outline: 2px solid rgb(94 59 183 / 30%);
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto 15px;
  display: block;
}

.ctm-col-wraper .group-wraper-right {
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
}

.ctm-col-wraper
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right
  svg
  path {
  fill: unset;
  stroke: #fff;
}

.ctm-col-wraper
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right
  svg
  path {
  fill: unset;
  stroke: #fff;
}

.ctm-col-wraper
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right
  svg.gender-both
  path {
  fill: #fff;
  stroke: unset;
}

.ctm-col-wraper
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right
  svg.gender-both
  path {
  fill: #fff;
}

.step-select {
  border: 1px solid #d9dbe9;
  padding: 6px 10px;
  border-radius: 6px;
  min-height: 40px;
  color: #170F49;
  font-weight: 500;
  margin-bottom: 12px;
  font-family: 'DM sans';
  font-size: 14px;
}

.col-1-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  max-width: 875px;
  margin: 0 auto;
}

.col-2-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  max-width: 875px;
  margin: 0 auto;
}

.action-wraper .group-wraper-right .group-name {
  text-align: center;
}

.action-title {
  text-align: center;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
}

.action-wraper .col-2-2 {
  gap: 20px;
}

.action-wraper .group-wraper-right {
  min-height: 170px;
}
.epr-tone{
  opacity: 0;
}

.action-shadow-box {
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  border-radius: 5px;
  padding: 10px 2px  10px 10px;
  min-height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 12px;
}

.action-tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #1c5dcd1a;
  color: black;
  width: 100%;
  padding: 10px 15px;
  border-radius: 7px;
  cursor: pointer;
}
.green-blue{
  background: #2C73FF;
  color: #fff;

}
.step-done-03 + div span.action-title {
  justify-content: space-between !important;
  width: 87%;
  max-width: 1075px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.message-action {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-height: calc(100vh - 390px);
  overflow: auto;
  padding-right: 4px;
}

.action-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.green-bg {
  background: #58af4d;
}

.blue-bg {
  background: #161f84;
}

.red-bg {
  background: #c93f31;
}

.request-main.col-1-3 {
  max-width: 1030px;
}

.request-wraper,
.interval-wraper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 0;
}

.request-wraper .group-items,
.interval-wraper .group-items {
  width: calc(50% - 7px);
}

.request-wraper .group-wraper-right,
.interval-wraper .group-wraper-right {
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.request-wraper .group-items:last-child {
  width: 100%;
}

.interval-wraper span.group-name span {
  font-size: 17px;
  display: block;
  text-align: center;
  margin-bottom: 4px;
}

.interval-wraper .group-name {
  text-align: center;
}

.interval-wraper .group-wraper-right {
  min-height: 95px;
}

.lunch-wraper .group-wraper-right {
  margin-bottom: 0;
}

.lunch-wraper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

span.group-name span {
  font-size: 14px !important;
}

span.group-name span span {
  font-size: 15px !important;
  text-transform: uppercase;
}
.keywords-wraper span.title-18 {
  margin-bottom: 0;
}
.keywords-wraper {
  margin-top: 12px;
}
.keywords-wraper select {
  margin: 0;
}
.group-wraper-right .group-name + svg {
  display: none;
}

.camp-main .btn-grooups button:hover {
  background: #2C73FF !important;
  color: #fff !important;
}

.request-main .group-wraper-right {
  padding: 5px 12px;
  min-height: 44px;
}
.group-wraper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.sidebar-main-parent a>img {
  width: 125px !important;
  height: 75px;
  object-fit: contain;
}

.message-right .group-wraper-right .group-name {
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.message-right .group-wraper-right .group-name>div:nth-child(01) {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  line-height: 1.25;
}
.message-right .group-wraper-right .group-name>div:nth-child(02) {
  align-items: center;
  background: #fff;
  border-radius: 6px;
  display: flex;
  padding: 3px 6px;
  border: 1px solid rgb(44 115 255 / 25%);
}
.message-right .group-wraper-right .group-name>div:nth-child(02) svg {
  color: rgb(44 115 255 / 75%);
  width: 16px;
}
.message-right input[type=radio]:checked+.group-wraper-right .group-name>div:nth-child(02) {
  background: #fff;
  border: 1px solid #2c73ff;
}
.message-right input[type=radio]:checked+.group-wraper-right .group-name>div:nth-child(02) svg path {
  fill: #2c73ff !important;
}
.message-right label.group-items .group-wraper-right {
  padding: 5px 5px 5px 12px;
  margin-right: 5px;
}
.manage-request-wrap .message-right label.group-items .group-wraper-right {
  padding: 5px 10px 12px;
  margin-right: 0px;
}

/* ________ Updated css ________ */
.message-right-step3 .group-items {
  height: 75px ;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8eefa;
  border-radius: 8px;
}

.message-right-step3 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.message-right-step3 input[name="gender"] + .group-wraper-right {
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.message-right-step3 .group-items .group-name {
    width: unset;
}
.message-right-step3 .group-items .group-name {
    font-size: 14px;
    font-weight: 500 !important;
    min-width: 52px;
    color: #170F49;
}
.message-right input[name="gender"]:checked + .group-wraper-right .group-name {
  color: #fff;
}
.message-right-step3-icons {
    display: flex;
}
.ctm-col-wraper .message-right input[name="gender"]:checked + .group-wraper-right svg.gender-both path {
    fill: #fff;
    stroke: unset;
}
.col-1-3-new .message-right label.group-items .group-wraper-right span {
  font-weight: 500;
}
.col-1-3.col-1-3-new.prospection-main-fb .col_1:nth-child(05) .lunch-wraper.lunch-wraper-fb {
    flex-direction: column;
    gap: 10px;
    background: transparent;
}
.col-1-3.col-1-3-new.prospection-main-fb .col_1:nth-child(05) .lunch-wraper.lunch-wraper-fb button {
    width: 100%;
}
.lunch-wraper.lunch-wraper-fb {
  display: flex;
  flex-direction: row;
  gap: 4px;
  background: #e8eefa;
  padding: 5px;
  border-radius: 6px;
  width:100%;
  margin: 0 auto;
}
.lunch-wraper.lunch-wraper-fb .group-items {
  flex-grow: 1;
  border-radius: 6px;
}
.lunch-wraper.lunch-wraper-fb .group-wraper-right:hover {
  background: #2C73FF;
  color: #fff;
}
.lunch-wraper.lunch-wraper-fb .group-wraper-right .group-name {
  font-weight: 500;
}
.action-wraper .ctm-col-wraper  .message-right .group-wraper-right {
    margin: 0;
    padding: 0;
    border-radius: 10px;
}
.action-wraper .ctm-col-wraper .message-right .group-wraper-right .group-name {
  font-size: 15px;
  font-weight: 500;
}
.action-title input {
  color: #170F49;
  font-family: 'DM Sans';
  padding: 8px 15px !important;
}
.col-2-2.action-wraper {
  margin-top: 12px;
  width: 87%;
  max-width: 1075px;
}
.ctm-col-wraper .group-name {
  color: rgb(23 15 73 / 90%);
}
.message-right input[name="keywords"]:checked + .group-wraper-right .group-name {
  color: #fff;
}
input[name="lunch"]:checked + .group-wraper-right .group-name {
  color: #fff;
}
input[name="convo"]:checked + .group-wraper-right .group-name {
  color: #fff;
}
.lunch-wraper.lunch-wraper-fb .group-wraper-right:hover .group-name {
  color: #fff;
}
.request-main-new label.group-items {
  border-radius: 6px;
}
.col_1.col_1_d-none {
  display: none;
}
.request-main-new {
  grid-template-columns: 1fr 1fr;
}
.request-main-new label.group-items {
  border-radius: 6px;
}
.request-main-new .keywords-wraper input.step-select {
  margin: 0;
}
.request-main-new .keywords-wraper>div {
  align-items: center;
  gap: 12px;
  display: flex;
  gap: 12px;
}
.strategy_btn{
  background-color:  #e8eefa;
  color: rgb(23 15 73 / 90%);
  
}
.strategy_active_btn{
  background-color:  #2C73FF;
  color: #fff;
}
.request-main-new .interval-wraper .group-wraper-right {
  height: 98px;
}
.message-right input[type="radio"]:checked + .group-wraper-right .group-name {
  color: #fff;
}
span.action-title-span {
  width: 49%;
}
.crm-birthdayOuter-new label.group-items {
  border-radius: 8px;
}
.crm-birthdayOuter-new .group-wraper-right .group-name {
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 1.25;
}
.group-wraper-right.group-wraper-right-bday .group-name {
    max-width: 150px;
}

.crm-birthday .message-right input[type="radio"]:checked + .group-wraper-right svg.bday-fill-stroke path {
  stroke: #2c73ff;
  fill: #fff;
}
.crm-birthday .message-right input[type="radio"]:checked + .group-wraper-right svg.bday-stroke path {
  stroke: #fff;
  fill: transparent;
}
.crm-birthdayOuter-new .message-right-01 label.group-items {
  margin-bottom: 12px;
  width: calc(100% - 6px);
}
.crm-birthdayOuter-new .crm-birthday.message-right-wrap {
  margin-right: 16px;
}
.crm-birthdayOuter-new .message-right.message-right-01 label.group-items .group-wraper-right {
  justify-content: space-between;
}
.crm-birthdayOuter-new .message-right.message-right-01 label.group-items .group-wraper-right>div:nth-child(02) {
  align-items: center;
  background: #fff;
  border-radius: 6px;
  display: flex;
  padding: 3px 6px;
  border: 1px solid rgb(44 115 255 / 25%);
}
.crm-birthdayOuter-new .message-right.message-right-01 label.group-items .group-wraper-right>div:nth-child(02) svg {
  color: rgb(44 115 255 / 75%);
  width: 16px;
}

.crm-birthdayOuter-new .message-right.message-right-01 label.group-items .group-wraper-right>div:nth-child(01) {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  line-height: 1.25;
}
.crm-birthdayOuter-new .message-right.message-right-01 input[type="radio"]:checked + .group-wraper-right svg path {
  stroke: unset;
  fill:  rgb(44 115 255 / 75%);
}

.lunch-wraper.lunch-wraper-fb.strategy-col-fb {
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: 0;
  gap: 10px;
}
.lunch-wraper.strategy-col-fb button {
  width: 100%;
  min-height: 44px;
}

@media (min-width: 1430px) {
  .group-wraper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1650px) {
  .steps-item span {
    font-size: 14px;
  }
}

@media (min-width: 1750px) {
  .group-wraper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 2100px) {
  .group-wraper {
    grid-template-columns: repeat(5, 1fr);
  }
 
}


@media (min-width: 2400px) {
  .group-wraper {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 2700px) {
  .group-wraper {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (max-width: 1360px) {
.shadow-wraper {
  padding: 15px 20px 10px;
}
}
@media (max-width: 1200px) {
  .content-box {
    padding: 0 20px;
}
  
}

@media (max-width: 1023px) {
  .request-wraper,
  .interval-wraper {
    gap: 16px 0;
    flex-direction: column;
  }

  .request-wraper .group-items,
  .interval-wraper .group-items {
    width: 100%;
  }

  .interval-wraper .group-wraper-right {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .col-2-1 {
    grid-template-columns: 1fr;
  }

  .content-title-box {
    flex-direction: column;
    gap: 16px;
  }

  .steps-bar {
    display: none;
  }

  .title-box {
    flex-direction: column;
    gap: 18px;
  }

  .title-21 {
    text-align: center;
  }

  .col-1-3 {
    grid-template-columns: 1fr;
  }

  .col-2-2 {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .action-wraper .group-wraper-right {
    min-height: 90px;
  }
}

@media (max-width: 600px) {
  .message-action {
    grid-template-columns: 1fr;
  }
  .group-wraper {
    grid-template-columns: 100%;
}
}



/* **************** Post image css ********************** */
.post-img-container.img-present-box{
  display: flex;
}

.group-wraper-inner.img-present-box2{
  width:70%;
  border-top-left-radius: 0px;
}
.group-footer.img-present-box3{
  border-bottom-left-radius: 0px;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner {
  background: #2C73FF;
  color: #fff;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner {
  background: #2C73FF;
  color: #fff;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner .group-footer {
  background: #2875fa;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner .group-footer svg path {
  fill: #fff;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner .group-name svg path {
  fill: #fff;
}

input[type="radio"]:checked + .post-img-container .group-wraper-inner .group-member {
  background: #2C73FF;
  border: 1px solid #fff;
}

label.group-items[for="Comment"],label.group-items[for="Like"] {
    height: 57px;
}
label.group-items[for="Comment"] .group-wraper-right, label.group-items[for="Like"] .group-wraper-right {
    width: 100%;
    height: 100%;
    margin: 0;
}

.col-1-3.col-1-3-new.prospection-main-fb{         
  grid-template-columns: calc(33% - 20px) calc(33% - 20px) calc(33% - 20px); 
  justify-content: space-between; 
  max-width: 1050px; 
  display: grid; 
}

.request-main-new.request-main.col-1-3{
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1050px;
}
.lunch-wraper.lunch-wraper-fb.strategy-col-fb {
  background: transparent;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  width: 100%;
}

.lunch-wraper.lunch-wraper-fb.strategy-col-fb button {
  min-height: 44px;
  width: 100%;
}
.message-right input[name="keywords"] + .group-wraper-right .group-name {
  text-align: center;
}

 .col-1-3.col-1-3-new.prospection-main-fb .lunch-wraper.lunch-wraper-fb {    max-width: 100%; 
  width: 100%; }

.col-1-2-new {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 30px;
  max-width: 675px;
  margin: 0 auto;
  justify-content: space-between; 
}