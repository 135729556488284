


.exclusive-header {
    background: rgb(44 115 255 / 10%);
    padding: 10px 0;
}
.exclusive-header  #customizer-select-locale {
    background: white;
    justify-content: flex-start!important;
    border-radius: 8px;
}
.exclusive-header  .aug-hd-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exclusive-header  .container {
    width:100%;
	max-width: 1275px; 
	margin:0 auto;
	padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.exclusive-header img {
    display: flex;
    width: 100%;
    max-width: 125px;
}
.exclisive-container {
    width: 100%;
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 20px;
}
.exclisive-title {
    color: rgb(23 15 73 / 75%);
    font-size: clamp(32px, 3.25vw, 48px);
    font-weight: 700;
    display: block;
    text-align: center;
}
.exclisive-wraper {
    padding: 75px 0;
    background: #ffffff;
}
.exclisive-row {
    margin-top: 65px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.exclisive-col {
    border-radius: 20px;
    background: rgb(77 112 247 / 5%);
    border: 1px solid rgb(77 112 247 / 15%);
    overflow: hidden;
    width: calc(33.33% - 24px);
    padding: 24px 30px 0;
    gap: 24px;
}

.blkFri-header {
    background: rgb(44 115 255 / 10%);
    padding: 10px 0;
}
.blkFri-header img {
    display: flex;
    width: 100%;
    max-width: 125px;
}
.blkFri-container {
    width: 100%;
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 20px;
}
.blkFri-banner {
    margin: 75px 0;
}

.blkFri-bannerInr {
    background-image: url(../../../../assets/img/blkFri-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
}

.blkFri-bnr-text {
    color: #fff;
}

.blkFri-bnr-img {
    width: 45%;
}

.blkFri-bnr-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.blkFri-bnr-img img {
    width: 100%;
}

.blkFri-bnr-text h2 {
    font-size: clamp(30px, 4.75vw, 60px);
    text-align: center;
    color: #FEC322;
    font-weight: 900;
    font-style: italic;
    margin: 0;
    line-height: 1.1;
}

.blkFri-bnr-text p {
    text-align: center;
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 1.5;
}

.blkFri-banner #tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.blkFri-banner #tiles span {
    width: 72px;
    padding: 12px 8px;
    text-align: center;
    font-size: 42px;
    color: #fff;
    background: linear-gradient(0deg, rgb(255 255 255 / 12%), rgb(255 255 255 / 5%));
    border-radius: 12px;
    letter-spacing: 0px;
    display: flex;
    letter-spacing: 3px;
    justify-content: center;
    border: 1px solid rgb(255 255 255 / 50%);
    font-family: 'Digital-7';
}   
.blkFri-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}
.blkFri-labels span {
    width: 72px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 12px;
    font-size: 12px;
}
.blkFri-footer {
    text-align: center;
    background: #f6f6f6;
    margin: 0;
    padding: 20px;
}
.blkFri-footer pre {
    margin: 0;
    font-size: 16px;
    font-family: 'DM Sans';
    color: rgb(0 0 0 / 70%);
}

.blkFri-warpper {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.blkFri-tab {
    cursor: pointer;
    padding: 10px 30px;
    background: #fff;
    display: inline-block;
    color: #170F49;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    min-width: 170px;
    text-align: center;
}
.blkFri-panels {
    min-height: 200px;
    width: 100%;
    overflow: hidden;
}
/* .blkFri-panel {
    display:none;
    animation: fadein .8s;
} */
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
.blkFri-plansWraper .radio {
    display:none;
}
#blkFri-month:checked ~ .blkFri-panels #blkFri-panel1 ,
#blkFri-year:checked ~ .blkFri-panels #blkFri-panel2 {
   display:block
}
#blkFri-month:checked ~ .blkFri-tabs #blkFri-tab1, 
#blkFri-year:checked ~ .blkFri-tabs #blkFri-tab2 {
    background: #4D70F7;
    color: #fff;
}
.blkFri-tabs {
    display: flex;
    gap: 12px;
    border: 1px solid  #DDE4FE;
    border-radius: 10px;
    padding: 8px;
    background: #F6F8FF;
}
.blkFri-plans-head {
    background: #2C73FF;
    color: #fff;
    padding: 16px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    position: relative;
}
.blkFri-fs-28 {
    font-size: clamp(22px, 2vw, 28px);
    font-weight: 700;
    letter-spacing: -.16px;
    line-height: 1.1;
}
.blkFri-plans-head::after {
    content: "";
    width: 20px;
    height: 20px;
    background: #2C73FF;
    bottom: -10px;
    position: absolute;
    left: calc(50% - 10px);
    transform: rotate(45deg);
}
.blkFri-fs-20 {
    padding: 36px 20px 24px;
    font-weight: 400;
    color: #170F49;
    font-size: 20px;
    display: block;
    text-align: center;
}
.blkFri-list {
    list-style-type: none;
    padding: 0;
    width: max-content;
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.blkFri-list li {
    font-size: 15px;
    color: #170F49;
    position: relative;
    padding-left: 30px;
}
.blkFri-list li::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(../../../../assets/img/blkFri-green-check.png);
    left: 0;
    top: 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
.blkFri-list li.blkFri-gray-check::after {
    background-image: url(../../../../assets/img/blkFri-gray-check.png);
}
.blkFri-priceMain {
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 30px 20px 24px;
    color: #2c73ff;
    font-size: clamp(36px, 4vw, 54px);
    font-weight: 700;
    font-style: italic;
    line-height: 1;
    letter-spacing: -.5px;
}
.blkFri-price-cut {
    color: #F05555;
    font-size: clamp(20px, 2vw, 24px);
    padding-bottom: 8px;
    text-decoration: line-through;
    font-style: italic;
    padding-right: 8px;
}
.blkFri-priceMain sub {
    font-size: 15px;
    font-weight: 500;
    transform: translate(5px, -7px);
    letter-spacing: 0;
}
.blkFri-price {
    line-height: 1;
    display: flex;
    align-items: end;
}
.blkFri-gray-text {
    display: block;
    text-align: center;
    padding: 0 20px 24px;
    color: rgb(23 15 73 / 65%);
    font-size: 15px;
}
.blkFri-plan-btn {
    background: #2C73FF;
    border: none;
    color: #fff;
    padding: 15px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto;
    font-family: 'DM Sans';
    width: 100%;
    max-width: 250px;
    display: block;
    text-transform: uppercase;
    letter-spacing: .16px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgb(0 0 0 / 0%);
    transition: .15s;
    text-align: center;
}
.blkFri-plan-btn:hover {
    background: #2369f3;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
}
.blkFri-plans {
    border: 1px solid rgb(77 112 247 / 15%);
    border-radius: 20px;
    overflow: hidden;
    background: rgb(77 112 247 / 4%);
    padding-bottom: 35px;
    width: calc(33.33% - 20px);
}
.blkFri-plansWraper {
    margin-bottom: 75px;
}
.blkFri-plans-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media (max-width: 1200px) {
    .blkFri-list {
        width: 100%;
        max-width: 100%;
        padding: 0 20px;
        gap: 15px;
    }
}

@media (max-width: 991px) {
    .blkFri-bnr-img img {
        max-width: 590px;
        margin: 0 auto;
        display: block;
    }
    .blkFri-bnr-text {
        width: 100%;
    }
    .blkFri-bannerInr {
        gap: 40px;
        padding: 50px 30px;
    }
    .blkFri-plans {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    .blkFri-plans-main {
        gap: 36px;
    }
    .blkFri-plansWraper {
        margin-bottom: 55px;
    }
    .blkFri-banner {
        margin: 55px 0;
    }
    .blkFri-warpper {
        gap: 30px;
    }
    .blkFri-banner #tiles {
        gap: 8px;
    }
    .blkFri-banner #tiles span {
        width: 60px;
        padding: 10px;
        font-size: 32px;
    } 
    .blkFri-labels span {
        width: 60px;
        font-size: 12px;
    }
    .blkFri-labels {
        gap: 8px;
    }
    .blkFri-bnr-img {
        width: 100%;
    }
}

.cybFri-banner {
    margin: 75px 0;
}

.cybFri-container {
    width: 100%;
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 20px;
}

.cybFri-bannerInr {
    background: radial-gradient(#1C1D6A, #1A0F36);
    border-radius: 20px;
    padding: 0 50px 55px;
    display: grid;
    grid-template-columns: 58% 35%;
    justify-content: space-between;
}

.cybFri-bnr-img img {
    width: 100%;
}

.cybFri-bnr-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #fff;
    padding-top: 60px;
}

.cybFri-bnr-text h2 {
    font-size: clamp(30px, 4.75vw, 60px);
    text-align: center;
    color: #EA12E0;
    font-weight: 900;
    font-style: italic;
    margin: 0;
    line-height: 1.1;
}

.cybFri-bnr-text p {
    text-align: center;
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 1.4;
}

.cybFri-banner #tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.cybFri-banner #tiles span {
    width: 64px;
    padding:  8px 10px;
    text-align: center;
    font-size: 42px;
    color: #fff;
    background: linear-gradient(0deg, rgb(255 255 255 / 12%), rgb(255 255 255 / 5%));
    border-radius: 8px;
    letter-spacing: 0px;
    display: flex;
    letter-spacing: 3px;
    justify-content: center;
    border: 1px solid rgb(255 255 255 / 15%);
    font-family: 'Digital-7';
}   
.cybFri-labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}
.cybFri-labels span {
    width: 64px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 12px;
    font-size: 12px;
}


@media (max-width: 1023px) {
    .cybFri-bannerInr {
        grid-template-columns: 100%;
            padding: 0 20px 55px;
    }
    .cybFri-bnr-img img {
        max-width: 525px;
        margin: 0 auto;
        display: block;
    }
    .cybFri-bnr-text {
        padding-top: 35px;
    }
    .cybFri-banner #tiles, .cybFri-labels {
        gap: 8px;
    }

}