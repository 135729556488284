.newLink {
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  color: #637381;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  background: rgb(94 59 183 / 0%);
  padding: 9px 15px;
  border-radius: 8px;
  transition: 0.2s;
}
.sidebar-link li a.newLink>span {
  display: grid  !important;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 12px;
}
.close-sidenav .sidebar-link li a.newLink {
  justify-content: center;
}
.close-sidenav .sidebar-link li a.newLink>span span {
  display: none;
}
.close-sidenav .sidebar-link li a.newLink>span {
  display: flex !important;
}
.onHover,
.onActive {
  display: none;
}

.sidebar-link > ul {
  margin: 5px 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style-type: none;
}
.sidebar-link li a.newLink span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -.2px;
}
.sidebar-link > ul li a svg {
  width: 22px;
}
/* .newLink:hover {
  background: rgb(44 115 255 / 10%);
} */
.newLink:hover .onStatic,
.newLink:hover .onActive {
  display: none;
}
.newLink:hover .onHover {
  display: block;
}
.newLink.onActiveLink .onStatic,
.newLink.onActiveLink .onHover {
  display: none;
}
.newLink.onActiveLink .onActive {
  display: block;
}
.newLink:hover {
  color: #2C73FF;
}
.newLink.onActiveLink {
  background: #2C73FF;
  color: #fff;
}
.css-fgp9os {
  justify-content: center !important;
}
.css-t5rexg {
  overflow: visible !important;
}
.sidebar-main-parent > div {
  overflow: visible;
}
.sidebar-main-parent img[alt="novalya"] {
  transform: translateY(5px);
  width: 110px !important;
}



#menu-appbar .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  top: 120px !important;
  left: 10px !important;
  width: 220px !important;
}
.main-side-bar {
  position: unset !important;
  overflow: unset !important;
  margin: 0 !important;
}
.main-side-bar .sidebar-user-btn {
  border: 1px solid rgb(44 115 255 / 15%) !important;
  background: rgb(44 115 255 / 7%) !important;
  border-radius: 25px !important;
  padding: 4px !important;
  margin: 0 auto 4px !important;
  display: flex;
}
.side-name-menu {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
}
.side-name-menu svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  width: 32px;
  height: 32px;
}
.side-name-menu p {
  flex-grow: 1;
  font-size: 15px;
  text-align: left;
  color: #2c73ff;
  font-weight: 500;
  letter-spacing: -.25px;
  font-family: "DM Sans", sans-serif;
}
svg.side-drop-arrow {
  width: 32px;
  height: 32px;
}
#menu-appbar .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  top: 120px !important;
  left: 10px !important;
  width: 220px !important;
}
#menu-appbar ul.MuiList-root.MuiMenu-list {
  padding: 12px 12px 0;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #cddeff;
  gap: 10px;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li {
  width: 100%;
  padding: 10px 14px;
  font-size: 16px;
  border-radius: 5px;
  gap: 16px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  letter-spacing: -.2px;
}
div#customizer-select-locale span {
  color: #170F49;
  padding-left: 3px;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -.2px;
  font-weight: 500;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li .MuiSelect-select {
  justify-content: flex-start;
  gap: 5px;
  padding: 6px !important;
  border-radius: 5px !important;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li div#customizer-select-locale span {
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -.2px;
  font-weight: 500;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li:nth-child(03) {
  padding: 0;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li:last-child {
  background: rgb(255 2 2 / 7%);
  margin: 0 -12px;
  width: calc(100% + 24px);
  padding: 12px 26px !important;
  color: #FF0202;
  border-top: 1px solid #2c73ff33;
  border-radius: 0 0 10px 10px;
}
#menu-appbar ul.MuiList-root.MuiMenu-list li:last-child svg path {
    fill: #FF0202 !important;
}
/* #menu-appbar ul.MuiList-root.MuiMenu-list li:hover {
  color: #2c73ff;
} */
li.profile-go:hover svg path, li.upgrade:hover svg path {
  fill: transparent !important;
  stroke: #2C73FF !important;
}
#menu-appbar .MuiMenu-list li.MuiMenuItem-root svg {
  width: 20px;
  height: 20px;
}
div#customizer-select-locale img {
  margin-right: 4px !important;
}
.sidebar-main-parent button.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
  width: 28px;
  height: 28px;
  right: 0 !important;
  top: 25px !important;
}
.sidebar-main-parent>.MuiDrawer-paperAnchorLeft>div>div:nth-child(01) {
  padding: 8px 15px  !important;
  min-height: unset;
}
.CmtLayout-main>.CmtLayout-content>button.MuiIconButton-edgeStart {
  top: 12px;
  transform: scale(.75) translateX(7px);
}
ul.sideNav>li {
  height: 44px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 8px !important;
}
ul.sideNav>li:hover {
  background: rgb(44 115 255 / 10%);
}

@media (max-width: 1200px) {
  div#logo-head {
    width: 100%;
    position: fixed;
    background: #fff;
    justify-content: space-between !important;
    box-shadow: 0 3px 7px rgb(0 0 0 / 15%);
}

  .sidebar-main-parent div#logo-head + div {
      display: none;
  }
  .sidebar-main-parent.close-sidenav div#logo-head + div {
      display: flex;
      min-height: calc(100vh - 60px);
      margin-top: 60px;
  }
  .sidebar-main-parent .MuiBackdrop-root.MuiModal-backdrop {
      display: none;
  }

  .sidebar-main-parent > .MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
      box-shadow: none;
      height: auto;
      width: 240px !important;
  }
  .MuiDrawer-root.MuiDrawer-modal.sidebar-main-parent {
      height: 60px;
  }

  .sidebar-main-parent.close-sidenav {
      height: unset;
  }

  .close-sidenav.sidebar-main-parent .MuiBackdrop-root.MuiModal-backdrop {
      display: block;
  }

  div.CmtLayout-main > .CmtLayout-content > button.MuiIconButton-edgeStart {
      top: 12px;
      transform: scale(0.85) translateX(0px);
      position: fixed !important;
      right: 25px;
      left: unset !important;
  }
  .sp-dashboard-main {
      padding: 0 15px;
  }
  div.sidebar-main-parent a>img {
    height: 45px;
    width: 100px !important;
  }
  .sidebar-main-parent button.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
    top: 18px !important;
    position: fixed !important;
    left: unset !important;
    right: 25px !important;
  }
  div.CmtLayout-main>.CmtLayout-content {
    padding: 60px 0 0 !important;
  }
  .main-side-bar {
    width: 100%;
    margin-top: 20px !important;
  }
  .close-sidenav .sidebar-link li a.newLink>span span {
    display: block;
  }
  .close-sidenav .sidebar-link li a.newLink {
    justify-content: start;
  }
  .main-side-bar .sidebar-user-btn {
    width: calc(100% - 24px);
    margin: 0 auto 12px !important;
  }
  .side-name-menu p {
    display: block !important;
  }
  svg.side-drop-arrow {
    display: flex !important;
  }
}
