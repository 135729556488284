.checkout .css-rfpk88-MuiPaper-root-MuiCard-root {
    background-color: #FFFFFF;
    color: #475259;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: hidden;
    border-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 0 0.5rem 1.25rem rgba(115, 82, 199, 0.175);
    background: url('../../../../public/images/wall/plans.jpg') no-repeat center;
    background-size: auto;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    height: 125px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .checkout .css-1rh4dn8-MuiPaper-root-MuiCard-root {
    background-color: #FFFFFF;
    color: #475259;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.25rem rgba(115, 82, 199, 0.175);
    position: relative;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  *, ::before, ::after {
    box-sizing: inherit;
  }
  