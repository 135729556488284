* {
  box-sizing: border-box; 

  
}
body {
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.ctm-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
  background-color: #cecece;
  border-radius: 3px;
}
.ctm-scroll::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
  background-color: #F5F5F5;
}
.ctm-scroll::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px;
}
.version-pop-main {
  background: rgb(0 0 0 / 70%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.version-pop-warper {
  background: #fff;
  border-radius: 10px;
  width: calc(100% - 40px);
  max-width: 775px;
  max-height: calc(100% - 80px);
  overflow: auto;
}
.version-pop-content {
  position: relative;
  padding: 25px 30px 25px;
}
.version-pop-close-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #D8D8D8;
  background: #F5F5F5;
  cursor: pointer;
  z-index: 1;
  transition: .15s;
  opacity: 1;
}
.version-pop-close-btn:hover {
  opacity: .8;
}
.version-pop-title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: rgb(0 0 0 / 70%);
}
.version-pop-username {
  color: #2C73FF;
}
.version-pop-loader {
  font-size: 15px;
  color: #2C73FF;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 24px;
}
.version-pop-loader-top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.version-pop-video-wraper {
  position: relative;
  width: 100%;
  margin: 16px auto 22px;
  padding: 28.2%;
  overflow: hidden;
  border-radius: 12px;
}
.version-pop-video-content {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}
.version-pop-btn {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 10px 24px;
  border: none;
  border-radius: 11px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  background: #41CE82;
  outline: 3px solid rgb(65 206 129 / 25%);
  transition: .25s;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .15px;
  margin: 0 auto;
}
.version-pop-btn:hover {
  background: #2fb76d;
}
.version-pop-btn svg {
  transform: translateX(0);
  transition: .25s;
}
.version-pop-btn:hover svg {
  transform: translateX(7px);
}
.popup-loader-dots-wraper {
  position: relative;
  display: flex;
  padding: 0 3px;
}
.version-loader-dots {
  position: relative;
  display: block;
  width: 6px;
  height: 6px;
  background: #2c89ff;
  transform: scale(0.025);
  margin: 2px;
  border-radius: 50%;
  animation: animatePopupdot 2s linear infinite;
  animation-delay: calc(.3s* var(--i));
  color: #063288;
}

@keyframes animatePopupdot {
  0%, 100% {
      transform: scale(.025);
  }
  50% {
      transform: scale(1);
  }
}

.version-loading-bar {
  position: relative;
  margin: 8px auto 0;
  width: max-content;
}
.version-loading-bar ul {
  list-style-type: none;
  padding: 0 5px;
  margin: 0;
  border: 2px solid #fff;
  outline: 2px solid #b1dcff;
  height: 24px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  width: max-content;
  overflow: hidden;
}
.version-loading-bar ul li {
  width: 12px;
  height: 16px;
  border-radius: 2px;
  background: #65B5FF;
  transform: skewX(-35deg);
  opacity: 0;
  animation: animatePopupline 3s linear infinite;
  animation-delay: calc(.1s* var(--i));
}
.version-loading-bar ul li:nth-child(odd) {
  background: #2C73FF;
}

@keyframes animatePopupline {
  100%, 0% {
      opacity: 0;
  }
  2% {
      opacity: 1;
  }
  50% {
      opacity: 1;
  }
  70% {
      opacity: 0;
  }
}


.version-pop-main>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.version-pop-main>div:focus-visible {
  outline: none;
}


@media (min-width: 1440px) {
  .version-pop-warper {
    max-width: 55%;
}
}



@media (max-width: 767px) {
  .version-pop-content {
    padding: 20px;
  }
  .version-pop-title {
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
  }
  .version-pop-close-btn {
    right: 8px;
    top: 8px;
    width: 24px;
    padding: 5px;
    height: 24px;
  }
}