* {
  box-sizing: border-box;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: "DM Sans", sans-serif;
}
.comment-ai-sec {
  padding: 0;
  margin: 10px 0;
}
ul {
  padding: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.container {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15pxs;
}
.ai-cards-hd {
  background: #2C73FF;
  color: #fff;
  font-size: 18px;
  padding: 8px 16px;
  display: block;
  margin-bottom: 40px;
  border-radius: 4px;
  margin-top: 30px;
}
.ai-cards-inner-number {
  background: #e0eafb;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2C73FF;
  margin-top: -22px;
  margin-bottom: 0;
}
.ai-cards-inner-hd {
  font-size: 16px;
  color: #170f49;
  font-weight: bold;
  margin-bottom: 0;
}
.ai-cards-inner-content {
  color: #170f49;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0;
}
.ai-commentment-cards {
  display: grid;
  grid-template-columns: 39% 59%;
  gap: 18px;
}
.ai-cards-inner {
  display: grid;
  gap: 45px 12px;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
}
.ai-cards-inner-div {
  box-shadow: 0 3px 15px rgb(0 0 0 / 7%);
  border-radius: 8px;
  padding: 0 10px 10px;
  min-height: 190px;
}
.ai-commentment-hd {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 0;
}
.ai-commentment-wrap {
  border-radius: 12px;
  box-shadow: 0px 5px 16px rgb(8 15 52 / 6%);
  padding: 24px;
  background: #fff;
}
.ai-commentment-videos {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.ai-commentment-videos .video-library-video iframe {
  max-width: 100%;
  width: 100%;
}
.ai-commentment-gender.group-items {
  background: rgb(94 59 183 / 10%);
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}
.ai-commentment-gender .group-items input[type="radio"] {
  display: none;
}
.ai-commentment-gender .group-wraper-right {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efebf8;
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 10px 12px;
  min-height: 46px;
  gap: 12px;
  cursor: pointer;
}
.ai-commentment-gender
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
}
.ai-commentment-gender .group-wraper-right .group-name {
  padding: 0;
  font-size: 14px;
}
.ai-commentment-gender
  .message-right
  input[name="gender"]:checked
  + .group-wraper-right
  svg
  path {
    stroke: #fff;
    fill: transparent;
}
.ai-commentment-gender-wrap {
  background: url(../images/bg-ai-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ai-commentment-gender-wrap .ai-commentment-gender {
  background: rgb(255 255 255 / 21%);
  /* background-image: url(http://localhost:3000/static/media/ai-gender-women2.png); */
  background-image: url(../images/ai-gender-women2.png);
  margin: 20px 35px;
  border: 1px solid rgb(255 255 255 / 52%);
  border-radius: 8px;
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}
.ai-commentment-video {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-top: 56%;
}
.ai-commentment-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ai-commentment-gender-wrap .message-right {
  max-width: 190px;
  padding: 20px;
  z-index: 9;
  position: relative;
}
.ai-commentment-gender-hd {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-top: 0;
}

@media (max-width: 991px) {
  .ai-commentment-cards {
    grid-template-columns: 100%;
    gap: 0;
  }
}

@media (max-width: 767px) {
  .ai-commentment-videos {
    grid-template-columns: 1fr;
  }
  .CmtLayout-content {
    padding: 0 10px !important;
  }
}

@media (max-width: 600px) {
  .ai-cards-inner {
    grid-template-columns: 1fr;
  }
  .ai-cards-inner-div {
    min-height: auto;
    padding: 5px 20px 20px;
  }
  .ai-cards-inner-div {
    min-height: auto;
  }
}
