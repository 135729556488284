* {
    box-sizing:border-box;
}
html,body{
    margin:0;
    padding:0;
    font-family: 'DM Sans';
}
img {
    max-width: 100%;
}
a {
    text-decoration: none;
}
ul {
    list-style: none;
}
img.MuiTelInput-FlagImg {
    width: 32px;
    height: 24px;
    transform: translateY(1px) translateX(6px);
}
.nw_form_group .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot img {
    width: 32px !important;
    height: 24px;
    border-radius: 5px;
    transform: translateY(1px);
}
.signup-container {
    width: 100%;
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 20px;
}
.signup-new-wraper {
    background: #f6f6f6;
}
.pricing-header {
    padding: 10px 0;
}
.pricing-header img {
    width: 100%;
    max-width: 115px;
    height: 50px;
    object-fit: contain;
    display: block;
}
.signup-blue-bar {
    background: #2C73FF;
    padding: 15px 0 12px;
    color: #fff;
}
.signup-blue-text {
    color: #fff;
    font-size: 16px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 12px;
    line-height: 1.25;
}
.signup-blue-text>span {
    font-size: 18px;
    font-weight: 600;
    transform: translateY(-3px);
}
.signup-column-row {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    gap: 36px 65px;
    padding: 35px 0 50px;
}
.pricing-left-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    border-bottom: 1px solid #D9D9DA;
    padding: 10px 0 16px;
}
.pricing-left-top-bar>a {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #2C73FF;
    text-decoration: underline;
    font-weight: 500;
}
.pricing-left-top-bar>a svg {
    width: 20px;
    height: 20px;
}
.sponsered-text {
    font-weight: 600;
}
.sponsered-text span {
    font-weight: 400;
}
.title-22 {
    font-size: 22px;
    font-weight: 600;
    color: #170F49;
    line-height: 1.25;
    display: block;
}
.title-20 {
    font-size: 20px;
    font-weight: 500;
    color: #170F49;
    line-height: 1.25;
    display: block;
}
.signup-wraper-left .mb-36 {
    margin-bottom: 36px;
}
.pricing-card-img {
    display: flex;
    align-items: center;
    gap: 20px;
}
.pricing-card-img img {
    width: 95px;
    height: 55px;
    object-fit: contain;
}
.pricing-features-list {
    padding: 0;
    margin: 25px 0 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.pricing-features-list li {
    display: flex;
    align-items: center;
    gap: 14px;
    color: #271563;
    font-size: 16px;
}
.pricing-card-img div {
    width: 125px;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    margin: 30px 0 36px;
}
.signup-left-bluewrap {
    background: #2C73FF;
    border-radius: 4px;
    padding: 24px 30px 30px;
    color: #fff;
    margin-bottom: 50px;
}   
.bluewrap-mid {
    margin: 16px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
}
.bluewrap-top {
    font-size: 18px;
    font-weight: 500;
    color: rgb(255 255 255 / 95%);
}
.bluewrap-mid span {
    font-size: 24px;
    font-weight: 700;
}
.bluewrap-price>span {
    font-size: 18px;
    font-weight: 400;
}
.bluewrap-bottom {
    opacity: .9;
    font-size: 16px;
}

.signup-right-shadow {
    background: #fff;
    border-radius: 16px;
    padding: 30px;
    box-shadow: 0 5px 30px rgb(0 0 0 / 7%);
}
.form_group_wraper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 20px;
}
.form_group_wraper>label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 12px;
}
.nw_form_group {
    position: relative;
    background: transparent;
    border-color: transparent;
    border-style: solid;
    border-width: 0px;
    border-radius: 6px;
    width: calc(50% - 10px);
    min-height: 46px;
}
.nw_form_group select {
    background: transparent;
    border: none;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #80858a;
}
.nw_form_group select + label.nw_form_label {
    opacity: 0;
}
.nw_form_input {
    width: 100%;
    background: transparent;
    border: transparent;
    font-size: 16px;
    font-weight: 400;
    color: #86829F;
    padding: 6px 15px;
    height: 100%;
}
.nw_form_input:focus {
    outline: none;
}
.nw_form_label {
    position: absolute;
    top: 11px;
    left: 15px;
    transition: all 100ms ease-in-out;
    pointer-events: none;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    font-family: 'DM Sans';
    color: #86829F;
}
.nw_form_input:focus~label, .nw_form_input:not(:placeholder-shown)~label {
    color: #6b6781;
    top: -10px;
    left: 12px;
    z-index: 1;
    background: linear-gradient(0deg, #f4f4f4, #fff);
    padding: 0 5px;
    font-size: 14px;
    font-weight: 500;
}
.nw_form_input::-webkit-input-placeholder{
    color:transparent;
}
.nw_form_input::-moz-placeholder{
    color:transparent;
}
.signup-wraper-right span.title-22 {
    text-align: center;
    padding-bottom: 16px;
}
.form_icons {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pricing-check-wraper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-right: 15px;
    margin-top: 5px;
}
.pricing-check-wraper input {
    transform: scale(1.15) translateY(3px);
    width: 20px;
    opacity: .75;
}
.pricing-check-wraper span {
    color: #475259;
    font-weight: 400;
    font-size: 13px;
    width: calc(100% - 40px);
}
.pricing-btn-box {
    margin: 10px 0 0;
    width: 100%;
}
.pricing-btn-box button {
    background: #2c73ff;
    outline: 3px solid rgb(44 115 255 / 30%);
    width: 100%;
    border: 0;
    color: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'DM Sans';
    transition: .2s;
}
.pricing-btn-box button:hover {
   background: #185fec;
}
.pricing-right-footer {
    display: block;
    margin-top: 25px;
    text-align: center;
    color: #475259;
    font-size: 15px;
}
.pricing-footer {
    text-align: center;
    background: #E5E5E5;
    margin: 0;
    padding: 16px;
    border-top: 1px solid #CFCFCF;
}
.pricing-footer pre {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: 'DM Sans';
    color: rgb(0 0 0 / 70%);
}
.pricing-check-wraper span a {
    color: #2c73ff;
    text-decoration: underline;
    font-style: italic;
    font-weight: 500;
}
.pricing-right-footer a {
    color: #2c73ff;
    font-weight: 500;
    text-decoration: underline;
}
.signup-blue-text>span {
    white-space: nowrap;
}
.signup-left-cardwrap span.title-22 {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nw_form_group fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border-radius: 8px;
}
.nw_form_group .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}
.nw_form_group .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    height: 46px;
    background: #F4F4F4;
    border: 1px solid rgb(39 21 99 / 15%);
    border-radius: 8px;
    padding: 0;
}
.nw_form_group .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    margin: 0 !important;
}
.nw_form_group .MuiInputLabel-formControl.MuiInputLabel-animated {
    top: 4px;
    font-family: 'DM Sans' !important;
}
.nw_form_group .MuiInputBase-fullWidth.MuiInputBase-formControl {
    padding: 0;
}
.nw_form_group .MuiInputLabel-shrink {
    background: linear-gradient(0deg, #f4f4f4 , white);
    padding: 0 4px;
    transform: translate(10px, -12px) scale(0.85) !important;
    color: rgb(23 15 73 / 65%);
    font-weight: 400;
}
.nw_form_group .Mui-focused {
    color: #86829F !important;
}
.nw_form_group .Mui-error {
    color: #86829F !important;
}

.nw_form_group img.MuiTelInput-FlagImg {
    border-radius: 5px;
}
.nw_form_group  .MuiInputAdornment-positionStart.MuiInputAdornment-sizeMedium {
    width: 42px;
}
.nw_form_group .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeSmall {
    width: 65px;
}
.nw_form_group p.Mui-error {
    margin: 5px 0 0 !important;
    line-height: 1.2;
    letter-spacing: 0;
    color: #ff0909 !important;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-family: 'DM Sans';
}
.nw_form_group.nw_form_zipcode .MuiInputLabel-formControl.MuiInputLabel-animated {
    top: -3px;
}
.nw_form_group.nw_form_zipcode  .MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
    top: 4px;
}
.nw_form_group.nw_form_zipcode .MuiInputLabel-formControl.MuiInputLabel-animated {
    top: -3px;
}
.nw_form_group.nw_form_zipcode  .MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
    top: 4px;
}

@media (max-width: 1250px) {
    .signup-column-row {
        gap: 16px 30px;
    }
}

@media (max-width: 1023px) {
    .signup-column-row {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767px) {
    .nw_form_group {
        width: 100%;
    }
    .signup-right-shadow {
        padding: 30px 20px;
    }
    .signup-wraper-right span.title-22 {
        text-align: left;
    }
    .pricing-card-img div {
        width: 100px;
        height: 65px;
    }
    .pricing-card-img {
        gap: 16px;
    }
    .pricing-card-img img {
        width: 80px;
        height: 50px;
    }
    .title-22 {
        font-size: 21px;
    }
    .title-20 {
        font-size: 18px;
    }
    .pricing-features-list li {
        font-size: 15px;
    }
    .signup-left-bluewrap {
        border-radius: 0px;
        width: calc(100% + 40px);
        margin: 20px -20px 30px;
    }
    .bluewrap-mid span {
        font-size: 20px;
    }
    .bluewrap-mid span {
        font-size: 21px;
    }
    .bluewrap-price>span {
        font-size: 16px;
    }
    .nw_form_group  .MuiInputLabel-formControl.MuiInputLabel-animated {
        top: 1px;
    }
    .signup-blue-text {
        font-size: 14px;
    }
    .signup-blue-text>span {
        font-size: 16px;
        font-weight: 500;
    }
    .signup-column-row {
        padding: 20px 0 25px;
    }
}




