button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}
.MuiCardHeader-title {
  margin: 0;
  font-size: 20px !important;
  font-weight: 500;
  padding-left: 36px !important;
}
.message-wraper {
  padding: 24px 0 0;
  border: 1px solid #ebecf3;
  border-radius: 12px;
  margin-bottom: 20px;
}
.edit-message-main ul.message-wraper {
  padding: 24px 0px 0px;
}
.edit-message-main form>div {
  margin: 0 !important;
  width: 100% !important;
}
.edit-message-main .button-footer {
  width: 100%;
  padding: 10px 24px;
}
.edit-message-main .half-field {
  max-width: 100%;
}
.edit-message-main .MuiChip-root.MuiChip-filled {
  background: #C4CCD2;
  font-weight: 500;
  letter-spacing: 0;
  padding: 12px;
}
.edit-message-main .MuiChip-root.MuiChip-filled span.MuiChip-label {
  font-size: 12px;
}
.MuiCardContent-root > .MuiBox-root {
  max-width: 800px;
  margin: 0 auto;
}

.MuiGrid-container {
  flex-direction: column;
  gap: 16px;
}

.MuiGrid-container input {
  background: #f6f6f6;
  border: 1px solid #ebecf3;
  border-radius: 8px !important;
}
.half-field {
  width: 100%;
  max-width: 400px;
  padding: 0 24px;
}
.message-wraper .MuiGrid-container .MuiGrid-item {
  max-width: calc(100% - 48px) !important;
  background: #f6f6f6;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #ebecf3;
}
.MuiGrid-container .MuiLoadingButton-root {
  border-radius: 10px;
  align-items: center;
  display: flex;
  line-height: 1;
  padding: 14px 28px 12px;
  width: max-content;
  margin-left: auto;
}
.MuiGrid-container .MuiLoadingButton-root {
  background: #2C73FF !important;
  border: none;
  outline: 3px solid rgb(44 115 255 / 15%);
}
.button-footer {
  background: #e0eafb!important;
  width: 100%;
  padding: 16px 30px;
  border-radius: 0 0 10px 10px;
}
.full-field {
  width: 100%;
  padding: 0 24px;
}
.full-field .MuiFormControl-fullWidth {
  width: 100%;
  background: #f6f6f6;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ebecf3;
}
.MuiToggleButtonGroup-grouped {
  font-size: 12px !important;
  text-transform: capitalize !important;
  letter-spacing: 0;
  color: #475259 !important;
  font-weight: 500 !important;
  border: 1px solid #dee2e6 !important;
  margin: 0 !important;
  min-width: 110px;
}

.MuiGrid-container svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 18px;
  color: #8c8c8c;
}
.outer-contaier.message-container > div {
  display: block;
}
.outer-contaier > div {
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-direction: row-reverse;
  gap: 10px;
  max-width: 800px;
  margin: 12px auto 0;
}
.message-header {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
  margin-bottom: 20px;
}
.message-header > button {
  padding: 0;
  width: 22px;
  height: 22px;
  min-width: 22px;
  border-radius: 50%;
  background: rgb(94 59 183 / 10%);
}
.message-header > button svg {
  width: 11px;
}
.msg-label.msg-label-space {
  padding-left: 24px;
  margin: 0;
}
.msg-label {
  margin: 0 0 12px;
  color: #475259;
  font-weight: 500;
}
.MuiGrid-item .MuiToggleButtonGroup-root button {
  padding: 5px 12px;
}
.MuiGrid-item .MuiToggleButtonGroup-root button:hover,
.MuiGrid-item .MuiToggleButtonGroup-root button.Mui-selected {
  background: #41ce81;
  color: #fff !important;
}

.message-wraper .MuiGrid-item .MuiToggleButtonGroup-root button:hover svg,
.message-wraper
  .MuiGrid-item
  .MuiToggleButtonGroup-root
  button.Mui-selected
  svg {
  color: #fff !important;
}
.half-field,
.full-field {
  margin-bottom: 10px;
}
.MuiGrid-item .MuiToggleButtonGroup-root button:focus {
  outline: none;
}

span.type-tags {
  background: rgb(28 93 205 / 10%);
  padding: 2px 10px;
  color: rgb(28 93 205 / 100%) !important;
  display: inline-block;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  margin: 1px;
}

.nova-box.library-box-only.library-messgae .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row,
.nova-box.library-box-only.library-keyword .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row {
    width: 100%;
    justify-content: space-between;
}

.nova-box.library-box-only.library-messgae .MuiDataGrid-virtualScrollerRenderZone,
.nova-box.library-box-only.library-keyword  .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
}
.nova-table-ctm .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell {
  max-height: max-content !important;
  width: 56% !important;
  min-height: 56px !important;
  max-height: 56px !important;
  border-color: transparent !important;
 
}
.nova-box.library-box-only.library-messgae .nova-table-ctm .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell {
  min-width: 460px!important;
}
.nova-table-ctm .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}
.nova-table-ctm .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer {
  width: auto !important;
}
.nova-table-ctm  .nova-name-header .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: start !important;
}
.nova-table-ctm .MuiDataGrid-withBorderColor {
  border-color: transparent !important;
}
.nova-box.library-box-only.library-messgae .MuiDataGrid-columnHeadersInner>div .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: start !important;
}
.library-keyword .nova-table-ctm .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: start!important;
}

.library-keyword .nova-table-ctm .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: start!important;
  min-width: 75px;
}
.library-keyword .nova-table-ctm .MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
}
.nova-box.library-box-only.library-messgae button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary {
  padding-left: 20px;
  padding-right: 20px;
}
.library-box-only .MuiDataGrid-cell button {
  font-family: 'DM Sans' !important;
  align-items: center;
  line-height: 1;
}
/* 
.nova-container .css-dcad29-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
  min-width: 593px !important;
 
} */

/* .nova-table-ctm .MuiDataGrid-row {
  max-height: 100px !important;
  min-height: 100px !important;
}

.MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-child(even) {
  max-height: 200px !important;
} */
.inner-child>div> svg.MuiSvgIcon-root {
  background: transparent;
  width: 28px;
  height: 41px;
  border-radius: 6px;
  padding: 3px;
}
.inner-child>div> svg.MuiSvgIcon-root:hover {
  cursor: pointer;
}
.form-contaier .footer-btn-group {
  margin: 0 -24px;
  width: calc(100% + 48px);
  border-radius: 0 0 10px 10px;
  background-color: #e0eafb !important;
}
.footer-edit-section {
  padding: 10px 24px;
}
button.section-delete-btn {
  background: #f5d7d2;
  display: flex;
  align-items: center;
  gap: 3px;
  color: #FF0202;
}

button.section-delete-btn svg path {
  fill: #ff274b;
}

button.section-delete-btn:hover {
  background: #FF0202;
  color: #fff;
}

button.section-delete-btn:hover svg path {
  fill: #fff;
}
.edit-section-wraper textarea {
  min-height: 22px !important;
  resize: auto;
  
}
.edit-section-wraper textarea   {
  min-height: 26px !important;
  padding: 0px !important;
  border-radius: 0 !important;
}
.message-container-create-new .message-wraper .MuiGrid-container .MuiGrid-item {
    flex-basis: 100%;
    width: 100%;
    max-width: 100% !important;
    border: none;
    background: transparent;
    border-radius: 0 0 10px 10px;
}
.message-container-create-new ul.MuiList-root.message-wraper {
    margin-bottom: 16px;
    padding-top: 16px;
}
.message-container-create-new ul.MuiList-root.message-wraper p.msg-label {
    margin-top: 20px;
}
.message-container-create-new ul.MuiList-root.message-wraper p.msg-label + div .MuiToggleButtonGroup-root {
    background: #f6f6f6 !important;
    padding: 10px;
    width: calc(100% - 30px);
    margin: 0 auto;
}
.message-container-create-new .message-wraper .MuiGrid-container {
    gap: 0px;
}

.library-keyword .nova-table-ctm .MuiDataGrid-virtualScroller {
  overflow-x: auto !important;
}
.nova-box.library-box-only.library-keyword .nova-table-ctm .MuiDataGrid-row .MuiDataGrid-cell:nth-child(03) {
    min-width: 350px!important;
}
.nova-box.library-box-only.library-keyword .nova-table-ctm .MuiDataGrid-row .MuiDataGrid-cell span {
    white-space: nowrap;
    letter-spacing: -0.25px;
}
 .library-box-only .MuiDataGrid-cell button svg.MuiSvgIcon-root {
    width: 18px;
  } 
  
.nova-box * {
  font-family: 'DM Sans' !important;
}