* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
.stage-01 {
  width: 100%;
}
div.container {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  padding: 0;
}
ul {
  padding: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.crm-listing-main > div::-webkit-scrollbar-track,
.dragable-listing-scroll::-webkit-scrollbar-track,
.MuiDialogContent-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}
.crm-listing-main > div::-webkit-scrollbar,
.dragable-listing-scroll::-webkit-scrollbar,
.MuiDialogContent-root::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.crm-listing-main > div::-webkit-scrollbar-thumb,
.dragable-listing-scroll::-webkit-scrollbar-thumb,
.MuiDialogContent-root::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
}

.ctm-horizontal-scroll::-webkit-scrollbar-track,
.crm-right-list-inner > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}
.ctm-horizontal-scroll::-webkit-scrollbar,
.crm-right-list-inner > div::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.ctm-horizontal-scroll::-webkit-scrollbar-thumb,
.crm-right-list-inner > div::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
}

form.serach-form {
  max-width: 100%;
}
.serach-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 100%;
  border: 1px solid #e0e1e3;
  border-radius: 5px;
}
.serach-form input {
  border: none;
  padding: 5px;
  color: #8a8c8d;
}
.search-btn {
  border: none;
  display: inline;
  padding: 0 7px 0 7px;
  border-right: 1px solid #e0e1e3;
  background: transparent;
  margin: 3px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 0;
}
.crm-right-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crm-top-add-btn {
  background: #41ce81;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 5px 11px;
  border-radius: 5px;
  white-space: nowrap;
  min-height: 34px;
}
.crm-top-add-btn>span {
  display: flex;
  align-items: center;
  line-height: 1;
}
.ctm-w-100 > div {
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.dragable-listing-scroll {
  height: calc(100vh - 255px);
  overflow: auto;
}
.crm-right-hd .crm-top-add-btn img {
  height: 14px;
  width: 14px;
}
.ctm-w-100 {
  height: 100%;
  width: 275px;
  min-width: 275px;
}
/* .ctm-w-100 {
  height: calc(100vh - 250px) !important;
  overflow: auto;
} */
.crm-hd {
  font-size: 18px;
  font-weight: bold;
  line-height: 35px;
  margin-top: 0;
  color: #170f49;
}

.crm-right-groupnova, .crm-right-groupnova-toggle {
  border: 1px solid #e0e1e3;
  border-radius: 12px;
  background: #fff;
  padding: 20px 20px 10px;
}

.crm-right-main-groups {
  padding-right: 20px;
  position: relative;
}
.crm-right-gruop > button {
  position: absolute;
  right: -11px;
  top: 5px;
  background: white;
}
.crm-right-gruop > button:hover {
  background: white;
}
.crm-right-gruop > button svg {
  fill: #777777;
}

.crm-right-main-groups::after {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% + 30px);
  background: #e0e1e3;
  right: 0;
  top: -20px;
}
div.crm-right-wrap {
  background: #f6f6f9;
  width: 100%;
  padding: 3px 0px 12px 0px;
}
.crm-listing-inn {
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;
  justify-content: space-between;
  padding: 8px 0;
}
.crm-listing-inn-headings {
  display: flex;
  align-items: center;
  gap: 25px;
}
ul.crm-listing-main > li:not(:last-child) {
  border-bottom: 1px solid #fff;
}
.crmlisting-suheading {
  position: absolute;
  top: 42px;
  right: 7px;
  background: #fff;
  padding: 8px;
  border-radius: 6px;
  z-index: 9;
  display: none;
  max-width: 127px !important;
  width: 100%;
}
.crm-listing-inn:hover .crmlisting-suheading {
  display: block;
}
.crm-listing-main {
  border: 1px solid #e0e1e3;
  background: #f0f5ff;
  border-radius: 8px;
  padding-left: 14px;
  padding: 0 0 0 7px;
}
.crm-listing-main .MuiBox-root {
  margin-bottom: 0;
  padding-left: 0;
}
.dragable-listing-box  {
  background: #e8eefa !important;
}
ul.crm-listing-main > div {
  height: calc(100vh - 205px);
  overflow: auto;
}
.crm-listing-dots {
  padding-right: 14px;
}
.crm-listing-namehd {
  color: #089230;
  font-size: 16px;
  font-weight: 600;
  background: #c7ead1;
  padding: 9px;
  border-radius: 10px;
  margin: 0;
}

.crm-listing-nmae {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
}
.crmlisting-subinntext {
  font-size: 12px;
  font-weight: 500;
  color: #2C73FF;
}
.crmlisting-subinntext-delte {
  font-size: 12px;
  color: #637381;
}
.crm-listing-namehd.bg-02 {
  color: #d74712;
  background: #ffd8ca;
}
.crm-listing-namehd.bg-03 {
  color: #006998;
  background: #d1e2e9;
}
.crm-listing-namehd.bg-04 {
  color: #a50d33;
  background: #f6c3cf;
}
.crm-listing-namehd.bg-05 {
  color: #5b7c05;
  background: #d6e5ae;
}
.crm-top-add-btn.crm-top-add-btn-color {
  background: #1b84ff;
  margin-left: 0 !important;
}
.user-data {
  padding-left: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #1b84ff;
}
.crm-right-list-inner > div {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
.crm-nove-user {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.MuiDialog-container .MuiListItemIcon-root .MuiBox-root {
  width: 100%;
}
.crm-nova-new.crm-hd {
  margin: 0;
  padding-left: 10px;
  width: 180px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crm-right-groupnova {
  display: grid;
  grid-template-columns: 275px 1fr;
  gap: 20px;
  margin-top: 8px;
}
.crm-right-groupnova-toggle {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 20px;
}
.crm-nova-serchbar {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}
ul.MuiList-root.MuiList-padding li {
  font-size: 14px;
  margin: 6px 8px;
  background: #fff;
  padding: 10px 16px;
}
ul.MuiList-root.MuiList-padding li:hover {
  background: #f0f5ff;
}
ul.MuiList-root.MuiList-padding li span {
  line-height: 1.15;
  font-weight: 500 !important;
}
ul.MuiList-root.MuiList-padding li>span {
  display: flex;
  align-items: center;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
  max-height: calc(100% - 24px);
  box-shadow: 0 4px 6px 2px #00000008;
}
.MuiDialog-container .MuiFormControl-root.MuiFormControl-fullWidth .MuiFormHelperText-sizeMedium {
  margin: 5px 2px 0;
  font-family: 'DM Sans';
  color: #5d5d5d;
}
.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  border-radius: 12px;
  min-width: 575px;
}
.MuiDialog-container.MuiDialog-scrollPaper p.MuiTypography-body2 {
  font-family: 'DM Sans';
  min-height: 50px;
}
.MuiDialog-container.MuiDialog-scrollPaper button.MuiButton-containedError {
  font-family: 'DM Sans' !important;
  text-transform: capitalize;
  letter-spacing: 0;
  min-width: 100px;
  border-radius: 8px;
  box-shadow: none;
  background: #fa2c2c;
  font-weight: 500;
}
.MuiDialog-container.MuiDialog-scrollPaper button.MuiButton-containedError:hover {
  background: #ea2c2c;
}
.MuiDialog-container.MuiDialog-scrollPaper p.MuiTypography-body2 {
  font-family: 'DM Sans';
  min-height: 50px;
}
.MuiDialog-container.MuiDialog-scrollPaper button.MuiButton-containedError {
  font-family: 'DM Sans' !important;
  text-transform: capitalize;
  letter-spacing: 0;
  min-width: 100px;
  border-radius: 8px;
  box-shadow: none;
  background: #fa2c2c;
  font-weight: 500;
}
.MuiDialog-container.MuiDialog-scrollPaper button.MuiButton-containedError:hover {
  background: #ea2c2c;
}
.stage-btn-group.MuiBox-root.css-1qvvkkh {
  padding-bottom: 10px;
}
.stage-btn-group button.MuiButton-containedPrimary.MuiButton-colorPrimary {
  box-shadow: none;
  border-radius: 8px;
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 500;
  font-family: 'DM Sans' !important;
  min-width: 100px;
}
.stage-btn-group button.MuiButton-containedPrimary.MuiButton-colorPrimary:hover {
  background: #2364e4;
}
.stage-btn-group .MuiBox-root {
  justify-content: flex-end;
  gap: 10px;
}
.stage-popup-main p.MuiTypography-body2 {
  min-height: auto !important;
}
.crm-nova-new {
  display: flex;
}
.user-data.user-datainn {
  color: #2C73FF;
}
.crm-stage-inn {
  background: #2C73FF;
  display: flex;
  padding: 13px 8px;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.crm-stage-01.crm-listing-main > div:not(:last-child) {
  border-bottom: 1px solid #fff;
}
.crmlisting-suheading li {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.crmlisting-suheading li:hover {
  background: rgb(94 59 183 / 10%);
}

.crm-nove-user.crm-nove-user-02 {
  background: white;
  max-width: 50px;
  border-radius: 5px;
  padding: 4px 8px;
}
.crm-right-search {
  padding-top: 8px;
  padding-bottom: 12px;
}
.crm-novastages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-top: 20px;
  min-height: 626px;
}
.crm-nove-check-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}
.crm-stage-01.crm-listing-main,
.crm-stage-03.crm-listing-main,
.crm-stage-02.crm-listing-main {
  padding-left: 0;
}
.crm-stage-inn.crm-stage-innerdiv {
  background: unset;
}
.crm-checkdiv-profile-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px !important;
  display: block;
  word-break: break-all;
}

.crm-checkdiv-profile {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crm-stage-check {
  display: flex;
  gap: 4px;
}
.crm-stage-check label {
  color: #fff;
  font-size: 14px;
}
.crm-nove-check-wrap .crm-listing-dots {
  padding-right: 0;
}
.crm-top-add-btn:hover .crmlisting-suheading.crmlisting-suheading-new {
  display: block;
}

.crmlisting-suheading.crmlisting-suheading-new {
  max-width: 160px !important;
  top: 26px !important;
}

.crmlisting-suheading.crmlisting-suheading-new li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.crm-stage-inn:hover .crmlisting-suheading.crmlisting-suheading-staging {
  display: block;
  top: 25px;
}
.crmlisting-suheading.crmlisting-suheading-profil {
  top: 34px;
  max-width: 160px !important;
}
.crm-stage-inn.crm-stage-innerdiv:hover
  .crmlisting-suheading.crmlisting-suheading-profil {
  display: block;
}
.crm-right-list-inner {
  display: flex;
  flex-direction: column !important;
}
.crm-right-list-inner > div:nth-child(02) {
  flex-grow: 1;
  padding-bottom: 5px;
  max-width: 100%;
}

/* .crm-right-list-inner > div:nth-child(02) > div {
  height: calc(100% - 5px);
  
}
.crm-right-list-inner > div:nth-child(02) > div > div,
.crm-right-list-inner > div:nth-child(02) > div > div > div {
  height: 100%;
} */
.MuiDialog-paper .MuiTypography-root.MuiTypography-h6 {
  color: #170f49;
  font-family: DM Sans;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0 !important;
}
form.serach-form {
  max-width: 100% !important;
}
.user-count {
  background: #e9f3ff;
  padding: 6px 8px;
  border-radius: 6px;
  min-width: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #d4e2ff;
}
.green-pop-btn {
  background: #41ce81;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
  letter-spacing: 0;
  text-transform: capitalize;
  min-width: 100px;
}
.green-pop-btn:hover {
  background: #2aa863;
}
.gray-pop-btn {
  background: #d3d3d3;
  color: #222242;
  padding: 8px 16px;
  border-radius: 7px;
  letter-spacing: 0;
  text-transform: capitalize;
  min-width: 100px;
}
.gray-pop-btn:hover {
  background: #bbbbbb;
}
.red-pop-btn {
  background: red;
  color: white;
  padding: 8px 16px;
  border-radius: 7px;
  letter-spacing: 0;
  text-transform: capitalize;
  min-width: 100px;
}
.red-pop-btn:hover {
  background: #da5353;
}
.campaign-title {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: -30px;
  padding: 12px 0 6px;
}
.campaign-title h5 {
  color: #170f49;
  font-weight: 600;
  font-family: 'DM Sans';
  font-size: 18px;
}
.MuiDialog-container .MuiDialog-paper.MuiDialog-paperWidthMd {
  width: 100%;
  max-width: 575px;
  border-radius: 20px;
}
.MuiDialog-container .MuiDialog-paper.MuiDialog-paperWidthMd .MuiTypography-body2 {
  font-family: 'DM Sans';
  font-weight: 500;
  color: #2F2F2F;
  margin-bottom: 8px;
}
.camp-main {
  overflow-x: hidden;
  padding: 24px 30px !important;
}
.btn-grooups {
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.btn-grooups button span {
  display: block;
  width: 100%;
  font-size: 14px;
  pointer-events: none;
}

.btn-grooups button {
  flex-direction: column;
  background: rgb(94 59 183 / 10%);
  width: 31% !important;
  border-radius: 8px !important;
  font-family: 'DM Sans';
}
.stage-popup-main .btn-grooups {
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px 3%;
}

.btn-grooups button[aria-pressed="true"] {
  background: rgb(28 93 205 / 100%);
  color: #fff !important;
}
.MuiDialog-root.MuiModal-root > .MuiBackdrop-root.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.stage-btn-group {
  width: calc(100% + 40px);
  margin: 0 -20px !important;
  padding: 10px 30px;
  overflow: hidden;
}

.stage-popup-main {
  overflow: hidden !important;
  padding: 20px 20px 0 !important;
  overflow-y: auto !important;
  /* min-height: 175px; */
}
/* old code css  */
div.MuiBox-root .css-5og50z {
  justify-content: flex-end;
}
ul.MuiList-root {
  border: 1px solid #EBECF3;
  border-radius: 8px;
  margin-bottom: 0px;
  min-width: 80px;
  padding: 0;
}
.crm-right-hd button.MuiIconButton-sizeLarge {
  background: #f0f5ff;
  border: 1px solid #d4e2ff;
  padding: 10px;
}
.crm-right-groupnova-toggle  .MuiBox-root.css-18502hl {
  padding-left: 7px !important;
}
.ctm-listing-keywords  {
  margin-bottom: 16px;
}
.ctm-textarea {
  border: 1px solid #ebecf3;
  padding: 16px;
  border-radius: 6px;
  min-height: 110px;
  margin-bottom: 36px;
  text-transform: capitalize;
}
.ctm-textarea.textarea-m0 {
  margin-bottom: 0px;
}
textarea.ctm-textarea.textarea-m0 + p {
  font-size: 12px;
}
.stage-group {
  gap: 10px;
  max-width: 550px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  background: #f6f6f6;
  border-radius: 6px;
  border: 1px solid #ebecf3;
  padding: 12px 16px;
}

.stage-group > div > div {
  display: grid;
  grid-template-columns: 1fr 28px;
  gap: 4px;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  min-height: 26px;
}
.stage-group > div {
  border: 1px solid #c4ccd2;
  box-shadow: none;
  max-width: 110px;
  width: 100%;
  padding: 0;
}
span.stage-icons {
  border-left: 1px solid #c4ccd2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
}
.dragable-listing > div {
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  background: #e8eefa !important;
}
.crm-right-list-inner .ctm-w-100 {
  background: #e8eefa;
}
.dragable-listing-header {
  border-radius: 8px 8px 0 0 !important;
}
.dragable-listing button svg {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.dragable-listing > div > div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.create-keywords-wraper ul.MuiList-root {
  margin-bottom: 16px;
}
.dragable-listing a svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dragable-listing div > svg {
  width: 16px;
  height: 16px;
}
.crm-listing-main .MuiTypography-root {
  margin: 0;
}
.MuiMenu-list li.MuiMenuItem-root {
  gap: 8px;
}
.MuiMenu-list li.MuiMenuItem-root svg {
  width: 18px;
  height: 16px;
}
.MuiMenu-list li.MuiMenuItem-root:hover svg path {
  fill: #2C73FF;
}
.dragable-listing .MuiAvatar-circular,
.dragable-listing .MuiAvatar-circular img {
  border-radius: 6px !important;
}
.crm-right-groupnova-toggle ul.crm-listing-main {
  padding: 0;
}
.crm-right-list-inner > div > div {
  height: 100%;
}
.crm-right-groupnova .crm-right-list-inner > div > div {
  height: auto;
}
.stage-popup-main-text {
  padding: 0;
  margin-bottom: 0;
  min-height: 150px;
  justify-content: space-between;
}
.stage-popup-main-text > div:not(.stage-btn-group) {
  padding: 0;
}

.stage-btn-group {
  padding-bottom: 10px;
}
.gray-pop-btn {
  background: #d3d3d3;
  color: #222242;
  padding: 8px 24px;
  border-radius: 7px;
  letter-spacing: 0;
  text-transform: capitalize;
  min-width: 105px;
}
.stage-popup-main .stage-popup-main {
  min-height: unset;
}
.nova-warning-box div {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;
}

.dragable-listing-header  label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.crm-right-gruop>button>span {
  display: flex;
}
.camp-main .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 100%;
}
.stage-popup-main .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 100%;
}
.stage-popup-main .MuiBox-root>h5.MuiTypography-root.MuiTypography-h5 {
  font-family: 'DM Sans';
}
.stage-popup-main .MuiInputLabel-formControl.MuiInputLabel-animated {
  top: -5px;
  font-family: 'DM Sans';
  color: #475259;
}
.camp-main  .MuiSelect-select {
  padding-right: 30px !important;
}
.stage-popup-main .MuiSelect-select {
  padding-right: 30px !important;
}


@media (max-width: 1200px) {
  .crm-right-groupnova {
    margin: 12px 20px;
  }
  ul.crm-listing-main > div {
    height: auto;
  }
  .dragable-listing-scroll {
      height: auto;
  }

}


@media (max-width: 991px) {
  .crm-novastages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding-top: 20px;
    min-height: 626px;
  }
  .crm-right-main-groups {
    max-width: 100%;
  }
  .crm-novastages {
    grid-template-columns: 1fr;
  }
  /* .crm-right-groupnova {
    grid-template-columns: 50% 50%;
    gap: 20px;
  } */
  .crm-nova-serchbar {
    grid-template-columns: 56% 20% 20%;
  }
  .crm-nova-new {
    display: block;
  }
  .crm-nove-user {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .crm-right-main-groups {
    max-width: 100%;
  }
  /* .crm-right-groupnova {
    grid-template-columns: 1fr;
  } */
  .crm-nova-new {
    display: block;
  }
  .crm-nova-serchbar,
  .crm-novastages {
    grid-template-columns: 1fr;
  }
  .crm-nove-user {
    max-width: 100%;
  }
  .serach-form input {
    text-align: start;
  }
  .crm-nova-serchbar {
    flex-wrap: wrap;
    width: 100%;
  }
  .crm-right-list-inner button.crm-top-add-btn {
    flex-grow: 1;
    justify-content: center;
  }
  .camp-main {
    padding: 24px 15px !important;
  }
  .btn-grooups button {
      width: 100% !important;
  }
  .btn-grooups {
      gap: 16px;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters>span {
    white-space: normal;
  }

}



@media (max-width: 650px) {
  .crm-right-groupnova {
    grid-template-columns: 1fr;
    margin: 10px 15px 0;
  }
  .crm-right-gruop > button {
    position: unset;
  }
  .crm-right-main-groups::after {
    display: none;
  }
  .crm-right-main-groups {
    padding-right: 0;
  }
  ul.crm-listing-main > div {
    height: auto;
  }
  ul.crm-listing-main > div > div:last-child >div {
    margin-bottom: 0;
    border-bottom: 0px;
  }
  .crm-right-gruop>button {
    display: none;
  }
  .dragable-listing-scroll {
    height: auto;
  }
}