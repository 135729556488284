* {
    box-sizing: border-box;
}
body {
    padding: 0;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.ctm-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.ctm-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.ctm-scroll::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 3px;
}
.annaunce-pop-main {
    background: rgb(0 0 0);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    overflow-y: auto;
    padding: 0 25px;
    z-index: 99999;
}
.annaunce-pop-warper {
    background: #fff;
    border-radius: 20px;
    width: 100%;
    max-width: 750px;
    max-height: calc(100% - 80px);
}
.annaunce-pop-content {
    position: relative;
}
.annaunce-pop-close {
    background: #fff;
    border: 2px solid #ababab;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 50%;
    z-index: 3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}
.annaunce-pop-close:hover {
    background: #efefef;
}
.annaunce-pop-close svg path {
    transition: .2s;
}
.annaunce-pop-close:hover svg path {
    stroke: #494949;
}
.annaunce-pop-text>img {
    width: 100%;
    max-width: 25%;
    min-width: 175px;
    margin: 0 auto;
    display: block;
}
.annaunce-pop-text {
    padding: 6% 50px 8%;
}
.annaunce-btn {
    border: none;
    outline: 3px solid rgb(44 115 255 / 25%);
    background: #2C73FF;
    color: #fff;
    padding: 12px 30px;
    border-radius: 12px;
    font-size: 16px;
    font-family: 'DM Sans';
    margin: 24px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: .2s;
    cursor: pointer;
    max-width: fit-content;
}
.annaunce-btn:hover {
    background: #1b61eb;
}
.annaunce-btn svg {
    width: 24px;
    height: 8px;
    transform: translateX(0px);
    transition: .2s;
}
.annaunce-btn:hover svg {
    transform: translateX(5px);
}
.annaunce-pop-title {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 1.25;
    color: #170F49;
    font-weight: 600;
    max-width: 550px;
    margin: 12px auto 20px;
}
.announce-join-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    font-size: 18px;
    color: #2c73ff;
    font-weight: 500;
}.announce-german-flag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #000;
}
.announce-german-flag img {
    width: 45px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 25%);
}

@media (min-width: 2150px) {
    .annaunce-pop-warper {
        zoom: 1.35;
    }
}

@media (min-width: 2550px) {
    .annaunce-pop-warper {
        zoom: 1.35;
    }
}