* {
  box-sizing: border-box;
  font-family: "DM Sans";
}
body {
  padding: 0;
  margin: 0;
}
/* input,
textarea,
button {
  width: 100%;
} */
input:focus,
textarea:focus,
button:focus {
  outline: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth {
  box-shadow: none;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth {
  margin: 0 !important;
  background: transparent;
  overflow: hidden;
  max-height: unset !important;
}
.ag-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
  background-color: #cecece;
  border-radius: 3px;
}
.ag-scroll::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
}
.ag-scroll::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px;
}
.ag-create-textarea-items textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
  background-color: #cecece;
  border-radius: 3px;
}
.ag-create-textarea-items textarea::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
}
.ag-create-textarea-items textarea::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px;
}
.ag-create-textarea-wraper::-webkit-scrollbar-track,
.ag-msg-preview::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
}
.ag-create-textarea-wraper::-webkit-scrollbar,
.ag-msg-preview::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.ag-create-textarea-wraper::-webkit-scrollbar-thumb,
.ag-msg-preview::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border: 2px solid #cfcfcf;
  border-radius: 10px;
}
.ag-msg-popup {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ag-msg-popup-inner {
  background: #fff;
  border-radius: 16px;
  width: calc(100% - 30px);
  max-width: 1920px;
  max-height: calc(100vh - 65px);
  overflow: auto;
}
.ag-msg-popup-inner.ag-step-01 {
  max-width: 1450px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth{
  align-items: center !important;
}
.ag-msg-popup-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 24px;
  min-height: 425px;
  justify-content: center;
}
.version-pop-close-btn {
  position: absolute;
  right: 12px;
  top: 10px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  background: #f6f6f6;
  cursor: pointer;
  z-index: 1;
  transition: 0.15s;
  opacity: 1;
  padding: 0;
}
.ag-msg-step-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 36px;
}
.ag-msg-temp-select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.ag-msg-temp-select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  min-height: 225px;
  width: 100%;
  max-width: 30%;
  border-radius: 16px;
  border: 1px solid #cbe9fc;
  background: repeating-linear-gradient(165deg, #d3f2fc, #fff);
  padding-top: 10px;
  position: relative;
  cursor: pointer;
}
.ag-select-btn-blue {
  border: 1px solid #cbe9fc;
  background: repeating-linear-gradient(165deg, #d3f2fc, #fff);
}
.ag-select-btn-dark {
  border: 1px solid #cdcce7;
  background: repeating-linear-gradient(165deg, rgb(56 51 161 / 20%), #fff);
}
.ag-select-btn-voilet {
  border: 1px solid #ebcdf1;
  background: repeating-linear-gradient(165deg, rgb(177 54 198 / 15%), #fff);
}
.ag-select-btn-voilet .ag-msg-template-tag {
  background: #b136c6;
  outline: 3px solid rgb(177 54 198 / 20%);
}
.ag-select-btn-voilet .ag-msg-template-title {
  color: #b136c6;
}
.ag-select-btn-dark .ag-msg-template-tag {
  background: #3833a1;
  outline: 3px solid rgb(56 51 161 / 20%);
}
.ag-select-btn-dark .ag-msg-template-title {
  color: #3833a1;
}
.ag-msg-template-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "DM Sans";
  line-height: 1.2;
  min-height: 53px;
  color: #0864ef;
  letter-spacing: -0.5px;
  max-width: 210px;
}
.ag-msg-template-tag {
  background: #0864ef;
  color: #fff;
  padding: 8px 20px;
  border-radius: 25px;
  outline: 3px solid rgb(8 100 239 / 25%);
  font-family: "DM Sans";
  font-size: 15px;
  min-width: 105px;
  font-weight: 500;
}
.ag-msg-temp-select-btn::before {
  content: "";
  width: 135px;
  height: 135px;
  background-position: bottom right;
  background-size: cover;
  bottom: 0;
  right: 0;
  position: absolute;
  border-radius: 0 0 16px 0;
}
.ag-select-btn-blue::before {
  background-image: url(../images/blue-mask.png);
}
.ag-select-btn-voilet::before {
  background-image: url(../images/voilet-mask.png);
}
.ag-msg-temp-select-btn.is_active .ag-msg-template-tag,
.ag-msg-temp-select-btn:hover .ag-msg-template-tag {
  background: #fff;
}
.ag-msg-temp-select-btn.is_active .ag-msg-template-tag,
.ag-msg-temp-select-btn:hover .ag-msg-template-tag {
  background: #fff;
}
.ag-msg-temp-select-btn.is_active .ag-msg-template-title,
.ag-msg-temp-select-btn:hover .ag-msg-template-title {
  color: #fff;
}
.ag-msg-temp-select-btn.is_active .ag-msg-template-tag,
.ag-msg-temp-select-btn:hover .ag-msg-template-tag {
  background: #fff;
  outline: 3px solid rgb(255 255 255 / 25%);
}
.ag-select-btn-dark.is_active .ag-msg-template-tag,
.ag-select-btn-dark:hover .ag-msg-template-tag {
  color: #3833a1;
}
.ag-select-btn-voilet.is_active .ag-msg-template-tag,
.ag-select-btn-voilet:hover .ag-msg-template-tag {
  color: #b136c6;
}
.ag-select-btn-blue.is_active .ag-msg-template-tag,
.ag-select-btn-blue:hover .ag-msg-template-tag {
  color: #0864ef;
}
.ag-select-btn-blue.is_active,
.ag-select-btn-blue:hover {
  background: repeating-linear-gradient(165deg, #0864ef, #0043a9);
}
.ag-select-btn-dark.is_active,
.ag-select-btn-dark:hover {
  background: repeating-linear-gradient(165deg, #5f59cb, #3833a1);
}
.ag-select-btn-voilet.is_active,
.ag-select-btn-voilet:hover {
  background: repeating-linear-gradient(165deg, #be4ed1, #8d229f);
}
.is_active {
color: white;
background: #0864EF !important;
}
.ag-lg-btn {
  border-radius: 30px;
  border: 1px solid #ccc;
  height: 44px;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  line-height: 1;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 600;
  color: #747474;
  cursor: pointer;
  transition: 0.25s;
  min-width: 125px;
}
.ag-lg-btn.ag-bg-gray {
  background: #ececec;
}
.ag-lg-btn.ag-bg-gray:hover {
  background: #dfdfdf;
}
.ag-lg-btn.is_active {
  background: #0864ef;
  color: #fff;
}
.ag-lg-btn.is_active svg path {
  fill: #fff;
}
.ag-create-btn .ag-lg-btn.ag-bg-gray {
  background: #0864ef;
  color: #fff;
  border: 1px solid #2c73ff;
  transition: 0.25s;
  width: max-content;
}
.ag-create-btn .ag-lg-btn:hover {
  background: #0a57cb;
  border: 1px solid #2c73ff;
}
.ag-main-title {
  font-size: 19px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
}
.ag-create-msg-top-chip {
  border: 1px solid #9ed2ee;
  border-radius: 8px;
  background: #f1f5ff;
  display: flex;
  width: calc(100% - 133px);
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  margin-bottom: 10px;
}
.ag-create-msg-top-chip > span {
  color: #0864ef;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.ag-create-msg-count {
  padding: 6px 10px;
  font-weight: 600;
  color: #fff;
  background: #0864ef;
  border-radius: 8px;
}
.ag-create-msg-wraper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: start;
  justify-content: space-between;
}
.ag-create-msg-wraper-left {
  width: 73%;
}
.ag-create-msg-wraper-right {
  width: calc(27% - 20px);
}
.ag-create-textarea-wraper {
  overflow-y: auto;
  overflow-x: scroll;
}
.ag-create-msg-wraper-right {
  background: #f6f6f6;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  padding: 16px;
}
.ag-blueCard-main {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
}
.ag-blueCard-header {
  background: #2c73ff;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
}
.ag-blueCard-header-icon {
  width: 28px;
  height: 28px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
}
.ag-blueCard-main:not(:last-child) {
  margin-bottom: 16px;
}
.ag-blueCard-body {
  font-size: 14px;
  padding: 16px 12px;
  color: #616161;
}
.ag-blueCard-listing {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin: 0;
}
.ag-blueCard-listing li {
  position: relative;
  padding-left: 14px;
}
.ag-blueCard-listing li::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #8a8a8a;
  border-radius: 50%;
  left: 2px;
  top: 5px;
}
.ag-blueCard-body ul li img {
  width: 16px;
  height: 16px;
}
.ag-blueCard-header-icon img {
  width: 100%;
}
.ag-create-msg-leftLast {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 92px);
  background: #f6f6f6;
  border: 1px dashed #bebebe;
  border-radius: 12px;
}
.ag-add-varient-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #2c73ff;
  border: none;
  text-decoration: underline;
  background: transparent;
  min-height: 290px;
}
.ag-create-textarea {
  min-width: 225px;
  height: 100%;
  position: relative;
  margin: 12px 10px 0;
}
.ag-create-textarea-scroll {
  display: flex;
}
.ag-create-textarea-items {
  background: linear-gradient(180deg, #fcfcfc, #fff);
  border: 1px solid #d4e3ff;
  border-radius: 12px;
}
.ag-create-textarea-items textarea {
  border: none;
  background: transparent;
  width: 100%;
  flex-grow: 1;
  min-height: 275px;
  padding: 12px;
}
.ag-create-textarea-btn {
  display: grid;
  grid-template-columns: 1fr 1fr 24px;
  align-items: center;
  gap: 5px;
  padding: 10px 0 16px;
  justify-content: space-between;
  position: relative;
}
.ag-delete-btn {
  position: absolute !important;
  top: -12px;
  right: -12px;
  width: 25px;
  height: 25px;
  background: #fff3f3 !important;
  border-radius: 50% !important;
  border: 1px solid #ffd1d1 !important;
  padding: 6px !important;
}

.ag-delete-btn svg {
  width: 16px;
}
.ag-create-fname {
  background: rgb(8 100 239 / 5%);
  border: 1px solid rgb(8 100 239 / 15%);
  border-radius: 6px;
  padding: 6px;
  font-size: 11px;
  font-weight: 500;
  color: #2c73ff;
  width: 100%;
}
.ag-emoji-btn {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
}
.ag-create-textarea-footer {
  background: linear-gradient(180deg, #eaf1ff, #fafcff);
  border-top: 1px solid #d4e3ff;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  border-radius: 0 0 12px 12px;
}
.ag-textarea-count {
  font-size: 10px;
  font-weight: 600;
  color: rgb(38 38 38 / 50%);
}
.ag-ai-btn {
  display: inline-flex;
  width: max-content;
  align-items: center;
  gap: 6px;
  border: 1px solid #987efd;
  padding: 5px 8px;
  border-radius: 6px;
  background: #fff;
}
button.ag-ai-btn img {
  width: 15px;
}
.ag-create-msg-top-chip input {
  background: transparent;
}
.ag-create-textarea-btn button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
  margin: 0 !important;
}
.ag-ai-btn > span {
  background: linear-gradient(to right, #0800ff 0%, #f500ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.35px;
}
.ag-arrow-prev,
button.ag-arrow-next {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  background: #f3f3f3;
}
.ag-create-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 0px;
}
.ag-create-textarea-items {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ag-create-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ag-create-msg-innerMain {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: stretch;
  position: relative;
  justify-content: space-between;
}
.ag-create-leftInnerStart {
  width: 80%;
  flex-grow: 1;
}
.ag-create-msg-leftLast {
  width: 115px;
  height: 100%;
  position: relative;
  margin-top: 12px;
}
.ag-msg-preview {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 16px;
  padding-bottom: 16px;
  width: max-content;
  margin: 0 auto;
  max-width: 100%;
}
.ag-msg-preview > button,
button.ag-arrow-next {
  width: 30px;
}
.ag-msg-preview-items {
  max-width: 250px;
  min-width: 250px;
  padding-top: 0;
  position: relative;
}
.ag-msg-preview-items > img {
  width: 100%;
  height: 100%;
}
.ag-msg-preview-text {
    height: calc(100% - 55px);
    left: 19px;
    position: absolute;
    top: 36px;
    width: calc(100% - 40px);
    z-index: 1;
}
.ag-msg-preview-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 12px;
}
.ag-msg-preview-header > img {
  width: 32px;
  height: 32px;
  border: 1px solid #a3a3a3;
  border-radius: 50%;
}
.ag-previwer-name {
  color: rgb(255 255 255 / 85%);
  font-size: 14px;
  font-weight: 500;
}
.ag-previwer-name > span {
  display: block;
  font-size: 10px;
}
.ag-msg-preview-wraper > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.ag-msg-preview-textInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 0 28px 28px;
  overflow: hidden;
}
.ag-msg-preview-wraper {
  background: #232a3a;
  flex-grow: 1;
  border-top: 1px solid #4e4e4e;
  padding: 13px 8px 13px;
  display: flex;
  align-items: start;
  gap: 12px;
  overflow-y: auto;
}
.ag-msg-preview-wraper::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.ag-previwer-textwrap {
  position: relative;
  background: #f2f2f7;
  padding: 8px;
  border-radius: 0 6px 6px 6px;
  font-size: 12px;
}
.ag-msg-content {
  display: block;
  color: #2c2c2e;
  word-wrap: break-word;
}
.ag-msg-time {
  display: block;
  text-align: right;
  font-size: 10px;
  margin-top: 10px;
}
.ag-previwer-textwrap {
  position: relative;
  background: #fbfbfb;
  padding: 8px;
  border-radius: 0 6px 6px 6px;
  font-size: 12px;
  margin-top: 3px;
  max-width: 160px;
}
.ag-msg-content {
  display: block;
  color: #2c2c2e;
}
.ag-msg-time {
  display: block;
  text-align: right;
  font-size: 10px;
  margin-top: 10px;
}
.ag-previwer-textwrap::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 14px;
  top: 0;
  left: -12px;
  background-image: url(../images/top-vector-tip.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.ag-msg-preview-main {
  max-width: calc(100% - 80px);
  margin: 0 auto;
}
.ag-header-back-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ag-header-back-btn span.ag-main-title {
  margin: 0;
}
.ag-back-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 16px 5px 5px;
  background: #f4f4f4;
  border: 1px solid #ebeaea;
  border-radius: 25px;
  font-size: 14px;
  color: #808080;
  font-family: "DM Sans";
  font-weight: 500;
  transition: 0.2s;
  cursor: pointer;
}
.ag-back-btn:hover {
  background: #ececec;
}
.ag-back-btn span {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}
.ag-selectTemplate {
  display: grid;
  grid-template-columns: 200px 1fr;
  border: 1px solid rgb(44 115 255 / 20%);
  border-radius: 12px;
  overflow: hidden;
}
.ag-selectRight {
  padding: 20px;
  width: 100%;
  padding-left: 210px;
  overflow-y: auto;
  height: 100%;
}
.ag-selectLeft {
  background: rgb(44 115 255 / 5%);
  border-right: 1px solid rgb(44 115 255 / 20%);
  border-radius: 12px 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  width: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ag-selectLeft ul.ag-selectNav:nth-child(02) {
  border-top: 1px solid #cddeff;
}
.ag-selectNav {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.ag-selectNav li:not(:last-child) {
  border-bottom: 1px solid #cedcf8;
}
.ag-langSelect div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2c73ff;
}
.ag-selectNav li div {
  color: #222425;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  transition: 0.15s;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 6px;
  align-items: center;
}
.ag-selectNav li div:hover {
  color: #fff;
  background: linear-gradient(45deg, #0864ef, #3aacff);
}

.ag-selectNav .active {
  color: #fff;
  background: linear-gradient(45deg, #0864ef, #3aacff);
}
.ag-selectNav li {
  cursor: pointer;
}
.ag-btn-group button.ag-lg-btn:hover {
  background: #dbd9d9;
}
.ag-selectNav li div:hover svg path {
  fill: #fff;
}
.ag-selectNav {
  display: flex;
  flex-direction: column;
}
.ag-selectWraper {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  justify-content: center;
  gap: 16px;
}
.ag-selectItems {
  width: 100%;
  border-radius: 12px;
  background: linear-gradient(180deg, #fff, #ebf2ff);
  padding: 15px 15px 50px;
  border: 1px solid #e8f0ff;
  gap: 10px;
  position: relative;
}
.ag-favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #edf3fe;
  padding: 0;
  border: none;
  cursor: pointer;
  z-index: 3;
}
.ag-favorite svg {
  transition: 0.2s;
}
.ag-favorite.is_active svg path {
  fill: #ff5f5f;
}
.ag-favorite:hover svg path {
  fill: #ff5f5f;
}
.ag-favorite-active{
  fill: #ff5f5f !important;

}
.ag-selectBtnWrap {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 2px;
  position: absolute;
  width: calc(100% - 30px);
  left: 15px;
  bottom: 15px;
}
.ag-BorderBtn {
  border-radius: 30px;
  border: 1px solid rgb(44 115 255 / 25%);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  background: #fff;
  color: #2c73ff;
  cursor: pointer;
  transition: 0.25s;
}
.ag-BorderBtn:hover {
  background: rgb(44 115 255 / 7%);
  color: #2c73ff;
}
.ag-selectTitle {
  color: #170f49;
  font-weight: 400;
  font-size: 16px;
  display: block;
  line-height: 1.3;
  margin-bottom: 15px;
  margin-top: 12px;
  word-wrap: break-word;
  display: block;
}
.ag-FilBtn {
  border-radius: 30px;
  border: 1px solid rgb(44 115 255 / 25%);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  background: #2c73ff;
  color: #fff;
  cursor: pointer;
  transition: 0.25s;
}
.ag-FilBtn:hover {
  background: #2165ed;
  color: #fff;
}
.ag-selectNav select {
  background: #2c73ff;
  color: #fff;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
}
.ag-selectNav select:focus {
  outline: none;
}
.ag-selestImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background: #edf3fe;
  border-radius: 12px;
}
.ag-btn-group {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}
.ag-btn-group .ag-lg-btn {
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
}

.ag-btn-group .ag-lg-btn.ag-bg-gray {
  background: #0864ef;
  color: #fff;
  border: 1px solid #2c73ff;
  transition: 0.25s;
  width: max-content;
}
.ag-btn-group .ag-bg-gray:hover, .ag-btn-group .ag-lg-btn.ag-bg-gray:hover {
  background: #0a57cb;
  border: 1px solid #2c73ff;
}
.serach-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 235px;
  border: 1px solid #d5e3ff;
  border-radius: 6px;
}
.serach-form input {
  border: none;
  padding: 6px;
  color: #8a8c8d;
  border-radius: 6px;
}
.search-btn {
  border: none;
  display: inline-flex;
  padding: 2px 7px 2px 7px;
  border-right: 1px solid #d5e3ff;
  background: transparent;
  margin: 3px;
  cursor: pointer;
  font-size: 14px;
}
.search-btn svg {
  width: 18px;
}

.serach-form svg path {
  fill: #2c73ff;
}
.ag-language {
  background: rgb(44 115 255 / 7%);
  border: 1px solid rgb(44 115 255 / 20%);
  padding: 5px 10px;
  border-radius: 8px;
  min-height: 34px;
  color: #2C73FF;
}
.crm-right-search {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ag-select-temp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ag-select-temp-header .ag-header-back-btn {
  margin: 0;
  gap: 10px;
}
.ag-select-temp-header {
  margin-bottom: 16px;
}
.ag-template-mainWrap {
  overflow-y: hidden;
  min-height: 100%;
}
.ag-btn-group button.ag-lg-btn {
  background: #E7E7E7;
  font-weight: 500;
}
.ag-template-steps {
  overflow: hidden;
}
.ag-template-steps .ag-msg-popup-content {
  height: calc(100vh - 100px);
  max-height: 90vh;
}
.ag-template-mainWrap .ag-selectTemplate {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.ag-template-mainWrap .ag-selectTemplate {
  display: block;
}
.ag-template-mainInner {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ag-selectNav li.active div {
  color: #fff;
}
.ag-selectNav li.active div svg path {
  fill: #fff;
}
.ag-select-temp-header .serach-form {
  max-width: 239px;
  border: 1px solid #D5E3FF;
  border-radius: 8px;
}
.ag-select-temp-header .search-btn {
  border-right: 1px solid #D5E3FF;
}
.ag-msg-step-03>span {
  display: block;
  margin: 0 0 20px 40px;
}
.message-right-child {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.message-right-child form.serach-form {
  width: calc(100% - 50px);
}

.message-right .message-right-child>button.MuiButtonBase-root {
  width: 35px !important;
  max-width: 35px !important;
  overflow: hidden;
  min-width: unset;
  min-height: 35px;
  margin: 0;
}
.ag-msg-back-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}
.ag-msg-back-btn .ag-main-title {
  margin: 0;
}
.ag-select-temp-header .crm-right-search {
  padding-top: 0;
  padding-bottom: 0;
}
.ag-select-temp-header .ag-msg-back-btn {
  margin: 0;
}
.ag-message-save-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  width: 90%;
  max-width: 670px !important;
  margin: 0 !important;
  padding: 40px 40px 20px;
  border-radius: 20px;
}
.ag-message-save-popup .ag-modal-content h3 {
  margin: 0 0 10px;
  font-weight: 500;
  color: #170F49;
}
.ag-message-btn-wraper {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 8px;
  margin-top: 70px;
}
.ag-message-btn-wraper button {
  box-shadow: none;
  border-radius: 8px;
  letter-spacing: 0;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 36px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'DM Sans';
}
.ag-message-save-btn {
  display: flex;
  gap: 8px;
}
button.pop-green-btn {
  background: #22C55E;
}
button.pop-green-btn:hover {
  background: #17af4f !important;
}
button.pop-red-btn {
  background: #FAE0DB;
  color: #FF0202;
  border: 1px solid #ffbcbc;
}
button.pop-red-btn:hover {
  background: #FF0202 !important;
  color: #fff;
}
.ag-ai-btn>span {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.ag-blueCard-main-lastChild .ag-blueCard-header {
  background: #8236C6;
}

.ag-blueCard-main-lastChild .ag-blueCard-header svg path {
  fill: #8236C6;
}
.ag-select-btn-voilet:before {
  background-repeat: no-repeat;
}

.ag-create-textarea-btn aside {
  border: none;
  position: absolute !important;
  height: 300px !important;
  width: 242px !important;
  z-index: 999;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 25%);
  top: unset;
  left: calc(100% + 3px);
  bottom: 17px;
}
.ag-create-textarea-scroll .ag-create-textarea:last-child aside {
  left: unset;
  right: calc(100% + 4px);
}
li.epr-emoji-category {
  zoom: .77;
}
div#epr-category-nav-id {
  zoom: .77;
  justify-content: center;
  align-items: center;
}
.ag-create-textarea {
  position: relative;
}

.custom-language-cs .css-vj0i06-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root #customizer-select-locale {
  justify-content: flex-start !important;
}
/* .ag-create-textarea-btn>div {
  position: absolute;
  bottom: 14px;
  transform: scale(.65);
  left: 101%;
  width: auto !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 20px;
  z-index: 99;
} */
.ag-create-textarea-scroll .ag-create-textarea:last-child .ag-create-textarea-btn>div {
  left: -34%;
}
.ag-selectItems .ag-selectBtnWrap button.ag-BorderBtn {
  max-width: 48%;
  font-family: 'DM Sans';
}
.ag-selectItems .ag-selectBtnWrap>div {
  width: 48%;
}
.ag-selectItems .ag-selectBtnWrap>div button {
  font-family: 'DM Sans';
}

.nw-msg-footer {
  border: 1px solid #E6E6E6;
  flex-grow: 1;
  padding: 16px 16px 16px;
  border-radius: 12px;
  max-width: 868px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nw-msg-textIcon {
  display: flex;
  align-items: center;
}

.nw-msg-textIcon>span {
  color: #170F49;
  font-size: 16px;
  font-weight: 600;
}

.nw-msg-dropbox {
  position: relative;
}

.nw-msg-dropCotent {
  position: absolute;
  min-width: 185px;
  max-width: 350px;
  background: #373D3F;
  min-height: 75px;
  border-radius: 5px;
  left: 100%;
  top: -40px;
  opacity: 0;
  transform: scale(0);
  transition: .15s;
  transform-origin: top left;
  color: #fff;
  padding: 9px;
  font-size: 11px;
}
.nw-msg-dropIcon {
  padding: 5px 10px;
  display: flex;
}

.nw-msg-dropCotent::after {
  content: "";
  width: 14px;
  height: 14px;
  background: #373d3f;
  left: -5px;
  top: 45px;
  z-index: 99;
  position: absolute;
  transform: rotate(45deg);
}

.nw-msg-dropbox:hover .nw-msg-dropCotent {
  opacity: 1;
  transform: scale(1);
}

.nw-msg-footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.nw-msg-links {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #2C73FF;
  background: rgb(44 115 255 / 10%);
  border: 1px solid rgb(44 115 255 / 50%);
  padding: 6px 10px;
  border-radius: 6px;
  font-weight: 500;
  text-decoration: none;
  transition: .2s;
  font-size: 14px;
  cursor: pointer;
}


.nw-msg-links:hover, .nw-msg-links.is_active {
  background: rgb(44 115 255 / 100%);
  border: 1px solid rgb(44 115 255 / 100%);
  color: #fff;
}

.nw-msg-links:hover .nw-msg-stroke path,
.nw-msg-links.is_active .nw-msg-stroke path {
  stroke: #fff;
}

.nw-msg-links:hover .nw-msg-fill path,
.nw-msg-links.is_active .nw-msg-fill path {
  fill: #fff;
}
.nw-msg-links:hover .nw-msg-stroke-fill path,
.nw-msg-links.is_active .nw-msg-stroke-fill path {
  fill: #fff;
  stroke: #fff;
}
.ag-msg-step-03 .ag-lg-btn {
  height: 38px;
  text-transform: capitalize;
  letter-spacing: 0;
  gap: 5px;
  font-family: "DM Sans";
}
.ag-msg-popup-content .serach-form {
  height: 36px;
  border-radius: 6px;
}
.ag-msg-popup-content div#customizer-select-locale span {
  color: rgb(23 15 73 / 75%);
  padding-left: 3px;
  font-size: 14px;
  font-weight: 400;
}
.ag-msg-popup-content .custom-language-cs {
  height: 36px;
  padding: 0 !important;
}
.ag-msg-popup-content div#customizer-select-locale {
  padding: 6px !important;
}
.ag-msg-popup-content fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d5e3ff !important;
  border-radius: 6px;
}

@media (min-width: 1440px) { 
  /* .ag-template-steps .ag-msg-popup-content {
    max-height: calc(100vh - 180px);
  } */
}

@media (min-width: 1600px) { 
  .ag-msg-popup-content {
    zoom: 1.2;
  }
  .ag-template-steps .ag-msg-popup-content {
    max-height: 75vh;
  }
  
}

@media (min-width: 1920px) { 
  .ag-msg-popup-content {
    zoom: 1.35;
  }
  .ag-template-steps .ag-msg-popup-content {
    max-height: 68vh;
  }
  .ag-selectWraper {
    grid-template-columns: repeat(5, 18.75%);
  }
}


@media (min-width: 2250px) { 
  .ag-msg-popup-content {
    zoom: 1.5;
}
}

@media (max-width: 1250px) {
  .ag-create-btn {
    margin-top: 24px;
    flex-direction: column;
    gap: 16px;
  }
  .ag-selectWraper {
    grid-template-columns: repeat(3, 31%);
  }
  .nw-msg-footer {
    flex-direction: column;
    gap: 16px;
    align-items: start;
}
}

@media (max-width: 1200px) {
  .ag-create-msg-wraper-left {
    width: 100%;
  }
  .ag-create-msg-wraper-right {
    width: 100%;
  }
  .ag-create-msg-top-chip {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .ag-selectWraper {
    grid-template-columns: repeat(2, 48%);
  }
  .ag-msg-temp-select-btn {
    max-width: 100%;
  }
  .ag-message-save-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: 92%;
    padding: 40px 15px 20px;
  } 
  .ag-message-btn-wraper button {
    padding: 0 12px;
  }
}

@media (max-width: 850px) {
  .ag-selectTemplate {
    grid-template-columns: 1fr;
  }
  .ag-create-msg-top-chip {
    width: calc(100% - 132px);
  }
  .ag-create-textarea {
    width: 100%;
  }
  .ag-select-temp-header {
    flex-direction: column;
    gap: 10px;
  }
  .ag-msg-step-03>span {
    margin: 0 0 20px 0px;
  }
  .ag-msg-preview-main {
    max-width: 100%;
  }
  .ag-msg-popup-content {
      padding: 40px 20px;
  }
  .version-pop-close-btn {
    height: 25px;
    right: 10px;
    top: 10px;
    width: 25px;
}
 
}

@media (max-width: 767px) {
  .ag-create-msg-leftLast {
    width: 100%;
  }
  .ag-add-varient-btn {
    min-height: 110px;
  }
  .ag-create-msg-top-chip {
    width: 100%;
  }
  .ag-template-mainWrap .ag-selectTemplate {
    display: block;
    overflow-y: auto;
  }
  .ag-selectLeft {
    position: unset;
    height: auto;
    width: 100%;
  }
  .ag-selectRight {
    padding-left: 0;
    overflow-y: auto;
    height: unset;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth {
    width: 100%;
  }
  .ag-select-temp-header .crm-right-search {
    width: 100%;
}
.ag-select-temp-header .serach-form input {
  text-align: start;
}
.ag-select-temp-header {
  align-items: start;
}
.ag-btn-group {
  flex-direction: column;
}
.ag-msg-temp-select-btn {
  max-width: 100%;
}
.version-pop-close-btn {
  height: 25px;
  right: 10px;
  top: 10px;
  width: 25px;
}
}

@media (max-width: 575px) {
  .ag-selectWraper {
    grid-template-columns: 100%;
  }
  .crm-right-search {
      flex-direction: column;
  }


}

.NewUpgrade_wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
  background-color: #cecece;
  border-radius: 3px;
}
.NewUpgrade_wrap::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
}
.NewUpgrade_wrap::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px;
}
