ul {
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

select:focus {
  outline: none;
}

.manage-request-left .message-right {
  padding: 0;
  z-index: 9;
  position: relative;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.crm-birthday-yt.manage-request-right,
.manage-request-left {
  border-radius: 12px;
  padding: 20px;
  background: #fff;
  border: 1px solid #f4eeeb;
}
.manage-request-left {
  padding: 16px 20px;
  display: grid;
  align-items: flex-start;
  gap: 4px;
}

.manage-request-left .group-items input[type="checkbox"] {
  display: none;
}

.manage-request-left .group-wraper-right {
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    max-width: 175px;
    width: 100%;
    text-align: center;
    min-height: 138px;
    background: rgb(44 115 255 / 10%);
    border-radius: 8px;
    border: 1px solid #e1e7f1;
}

.manage-request-left
  .message-right
  input[type="checkbox"]:checked
  + .group-wraper-right {
  background: #2C73FF;
  color: #fff;
  height: 100%;
}
.group-wraper-right > svg {
  width: 16px;
}
.manage-request-left .group-wraper-right > svg {
  width: 32px;
}
.manage-request-left
  .message-right
  input[type="checkbox"]:checked
  + .group-wraper-right
  .manage-req-slect
  select {
  background: #266ce4 !important;
  color: #fff;
  border: none;
}

/*--toggle-btn---*/
.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: absolute;
  vertical-align: middle;
  transition: background 0.25s;
  top: 30px;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

/*--toggle-btn---*/

.manage-req-slect select {
  width: 100%;
  background: #fff;
  border: 1px solid #d4d4d8;
  min-height: 36px;
  border-radius: 7px;
  padding: 6px 12px;
  text-transform: capitalize;
}

.manage-req-slect {
  max-width: 100%;
  width: 100%;
}

.mesg-hd.mesg-hd-left {
  text-align: left;
}

.manage-request-left .group-wraper-right {
  max-width: 100%;
  width: 100%;
  padding-top: 24px;
}

.crm-birthday-slect.crm-birthday {
  display: grid;
  align-items: center;
  gap: 12px 16px;
  grid-template-columns:  0% calc(100% - 16px);
  /* grid-template-columns: 1fr 1fr; */
}



.crm-birthday-slect.crm-birthday {
  width: 100%;
  min-height: 50px;
}

.manage-request-left .message-right label {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.crm-birthday-slect.crm-birthday select {
  width: 100%;
  max-width: 340px;
  background: #fff;
  border: 1px solid #f4eeeb;
  min-height: 40px;
  border-radius: 5px;
  padding: 10px;
  color: #637381;
}

.crm-birthday-slect select.actiongroup {
  width: auto !important;
}
.crm-deletebtn {
  background: #e11515;
  border: none;
  outline: 2px solid rgb(225 21 21 / 25%);
  padding: 8px 24px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin: 0 auto;
}
.crm-deletebtn:hover {
  background: #ca0101;
}
.request-btn-div {
  background: rgb(225 21 21 / 10%);
  padding: 20px;
  border-radius: 8px;
  min-height: 210px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.manage-request-wrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgb(8 15 52 / 7%);
  min-height: calc(100vh - 185px);
  align-items: flex-start;
}
.manage-req-slect .mesg-hd.mesg-hd-left {
  text-align: left;
  display: none;
}

.manage-request-right .mesg-hd.mesg-hd-left {
  text-align: center;
}

.request-btn-div p {
  font-size: 14px;
  font-weight: 500;
  color: #6d6d6d;
  text-align: center;
  line-height: 1.35;
  margin-bottom: 24px;
}

.crm-birthday-yt.manage-request-right {
  padding: 16px 16px 20px 16px;
}

.manage-request-left
  .message-right
  input[type="checkbox"]:checked
  + .group-wraper-right
  .check-change-svg
  path {
  fill: #fff;
}

.manage-request-left span.group-name {
  font-size: 14px;
  font-weight: 600;
  color: #170f49;
  max-width: 180px;
  line-height: 1.25;
}

.manage-request-left
  .message-right
  input[type="checkbox"]:checked
  + .group-wraper-right
  .group-name {
  color: #fff;
  font-weight: 500;
}
.manage-request-left button {
  letter-spacing: 0;
  padding: 5px 20px;
  box-shadow: none;
  font-size: 16px;
  border-radius: 12px;
  margin: 0;
}
.manage-request-left button:hover {
  box-shadow: none;
}


.manage-request input[value="true"] + .group-wraper-right::after,
input[value="1"] + .group-wraper-right::after {
  background: #fff;
  left: 32px;
}
.manage-request input[value="true"] + .group-wraper-right::before,
input[value="1"] + .group-wraper-right::before {
  background: #0be86f;
}
.manage-request-left .mesg-hd {
  text-align: center;
  margin: 10px 0 16px;
}
.manage-request-left a.MuiButtonBase-root {
  letter-spacing: 0;
  font-size: 14px;
  min-height: 40px;
  border-radius: 12px;
  padding: 6px 24px;
  margin-bottom: 0;
  box-shadow: none;
}
.crm-birthday-slect-wrap > div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
.crm-birthday.crm-birthday-slect-wrap {
  align-items: flex-start;
}
.crm-birthday.crm-birthday-slect-wrap > label {
  margin-top: 8px;
}
.crm-birthday-main .crm-birthday-slect-wrap > div label {
  display: none;
}
.crm-birthday-slect select.actiongroup {
  width: calc(33.33% - 10px) !important;
  min-width: unset;
  max-width: 100% !important;
}
.manage-request-wrap select {
  padding: 10px 16px;
  background: #f6f6f6;
}
.manage-request-wrap .manage-req-slect select {
  background: #fff;
}


@media (max-width: 991px) {
  .manage-request-wrap {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .manage-request-left .message-right {
    grid-template-columns: 1fr;
  }
}

/*---manage-=request-css---*/
