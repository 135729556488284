* {
    box-sizing: border-box;
}
body {
    padding: 0;
    margin: 0;
    font-family: "DM Sans", sans-serif;
}

.ctm-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.ctm-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.ctm-scroll::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 3px;
}
.mrkt-pop-main {
    background: rgb(0 0 0 / 65%);
    height: 100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    position: fixed;
    width: 100%;
    overflow-y: auto;
    padding: 0 25px;
    z-index: 999900000;
    left: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3vw;
}
.mrkt-pop-warper {
    background: #fff;
    border-radius: 10px;
    max-width: 30%;
    width: 100%;
    min-width: 450px;
}
.mrkt-pop-content {
    position: relative;
}
.mrkt-pop-close {
    background: #fff;
    border: 2px solid #D7D7D7;
    width: 30px;
    height: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    z-index: 3;
    cursor: pointer;
    padding: 0;
}
.mrkt-pop-imgWrap img {
    width: 100%;
}
.mrkt-pop-imgWrap {
    position: relative;
}
.mrkt-pop-img {
    position: relative;
    padding-top: 148%;
    overflow: hidden;
}
.mrkt-pop-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(1.0125);
}
.mrkt-footer {
    position: absolute;
    bottom: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 18px;
}
.mrkt-blue-text {
    color: #00F0FF;
    text-decoration: underline;
    font-size: clamp(14px, 1vw, 18px);
    font-style: italic;
    transition: .2s;
}
.mrkt-blue-text:hover {
    color: #05d9e6;
}
.mrkt-popup-btn {
    font-size: clamp(16px, 1.25vw, 22px);
    background: #41CE81;
    border: none;
    outline: 3px solid rgb(65 206 129 / 20%);
    border-radius: 8px;
    color: #fff;
    padding: 6px 24px;
    cursor: pointer;
    transition: .2s;
}
.mrkt-popup-btn:hover {
    background: #35be73;
}