* {
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  background: #F6F6F9;
}
button:focus {
    outline: none;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
input, textarea, select {
  width: 100%;
}
img {
  display: block;
  width: 100%;
}
tspan {
  font-family: "DM Sans", sans-serif !important;
}
.sp-stats-selectWrap * {
  font-family: "DM Sans", sans-serif !important;
}
div.sp-dashboard-main {
  height: unset !important;
}
.sp-dashboard-innerTop {
  display: grid;
  grid-template-columns: calc(66.66% - 9px) calc(33.33% - 9px);
  gap: 18px;
  margin-top: 28px;
}
.sp-shadow-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  padding: 16px 15px;
 
}
.sp-promore-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 175px;
}
.sp-title {
  font-size: 20px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #170F49;
  letter-spacing: -.35px;
  line-height: 1.2;
  font-family: "DM Sans", sans-serif;
}
.sp-title svg {
  transform: translateY(1px);
}
.sp-software-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sp-inactive-btn {
  background: #FF0202;
  border: none;
  padding: 6px 12px;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  font-size: 12px;
}
.sp-active-btn {
  background: #2C73FF;
  border: none;
  padding: 6px 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  font-size: 13px;
}
.sp-inactive-btn, .sp-active-btn {
  font-family: "DM Sans", sans-serif;
  letter-spacing: -.25px;
  font-weight: 600;
}
.sp-active-btn.active-ext {
  background: #00b150;
}
.sp-arrow-btn {
  background: #2C73FF;
  color: #fff;
  padding: 8px 16px;
  border: none;
  outline: 2px solid rgb(44 115 255 / 15%);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  font-family: 'DM Sans';
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 10px auto 0;
  letter-spacing: -.2px;
  transition: .2s;
  cursor: pointer;
  width: max-content;
}
.sp-arrow-btn:hover {
  background: #1b61eb;
}
.sp-arrow-btn svg {
  width: 16px;
  height: 10px;
  transform: translateY(1px);
  transition: .2s;
}
.sp-arrow-btn:hover svg {
  transform: translate(5px, 1px);
}
.sp-bg-green {
  background: #00B150;
  outline: 2px solid rgb(0 177 80 / 12%);
  border: none;
  min-height: 34px;
  min-width: 165px;
  justify-content: center;
  text-align: center;
}
.sp-bg-green:hover {
  background: #03a54c;
}
.sp-upgrade-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  font-size: 19px;
  flex-direction: column;
  font-weight: 500;
  background-image: url('../../img/sp-offer-icon.png');
  line-height: 1.25;
}
.sp-upgrade-icons>span {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}
.sp-upgrade-inner {
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 10px;
  line-height: 1.35;
}
.sp-upgrade-text span {
  color: #00B150;
}
.sp-social-wraper span.sp-title {
  margin-bottom: 16px;
}
.sp-social-wrapInner {
  display: grid;
  grid-template-columns: calc(50% - 9px) calc(50% - 9px);
  gap: 18px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.sp-social-wrapInner .sp-shadow-card {
  padding: 10px;
  min-height: 115px;
}
.sp-social-items>img {
  border-radius: 8px;
  width: 100%;
}
.sp-social-items {
  display: grid;
  grid-template-columns: 95px calc(100% - 105px);
  gap: 10px;
  position: relative;
  align-items: center;
}
.sp-connect-icons {
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  right: 7px;
  top: 7px;
}
.sp-social-detailsTop {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sp-connect-icons .sp-connect-reload,
.sp-connect-icons .sp-connect-link {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
} 
.sp-connect-reload {
  background: rgb(44 115 255 / 10%);
}
.sp-connect-link {
  background: rgb(225 21 21 / 10%);
}
.sp-social-detailsTop {
  display: flex;
  align-items: center;
}
.sp-social-name {
  background: rgb(44 115 255 / 5%);
  padding: 4px 8px 4px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0 15px 15px 0;
  color: #170F49;
  font-family: "DM Sans", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 80px);
}
.sp-social-title {
  color: #535353;
  font-size: 14px;
  font-weight: 500;
  margin: 9px 0;
  display: block;
  letter-spacing: -.2px;
  line-height: 1;
  font-family: "DM Sans", sans-serif;
}
.sp-social-info {
  display: flex;
  justify-content: space-around;
  gap: 4px;
}
.sp-social-info>div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.sp-social-info span {
  color: #999999;
  font-size: 10px;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  white-space: nowrap;
}
.sp-social-info .sp-social-infoCount {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  font-family: "DM Sans", sans-serif;
}
.sp-social-info .sp-text-red {
  color: #F25767;
}
.sp-social-info .sp-text-orange {
  color: #FFA000;
}
.sp-social-info .sp-text-green {
  color: #00B150;
}
.sp-videoMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.sp-videoMain span.sp-title {
  margin-bottom: 14px;
}
/* .sp-videoFrame {
  padding: 0;
  position: relative;
  padding-top: 72%;
  border-radius: 12px;
  overflow: hidden;
}
*/
.sp-videoFrame {
  min-height: 256px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
.sp-videos {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
} 
.sp-dashboard-topRight p {
  margin: 5px 0;
  line-height: 1.3;
  font-size: 14px;
  color: #535353;
  letter-spacing: -.2px;
  font-family: "DM Sans", sans-serif;
}
.sp-promore-wrap p {
  display: flex;
  align-items: center;
}
span.sp-upgrade-text {
  font-size: 14px;
  color: #535353;
  line-height: 1.3;
  font-family: "DM Sans", sans-serif;
}
.sp-limits-items>span {
  color: #535353;
  font-size: 13px;
  letter-spacing: -.3px;
  font-weight: 500;
  text-align: left;
  line-height: 1.25;
  font-family: "DM Sans", sans-serif;
}
.sp-limits-items>span.sp-limits-text {
  font-size: 18px;
  letter-spacing: -.75px;
  display: block;
  font-weight: 600;
  line-height: 1.3;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "DM Sans", sans-serif;
}
.sp-limits-items {
  text-align: center;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  width: 50%;
  min-height: 81px;
  border-bottom: 6px solid #fff;
  padding: 6px 13px;
}
.sp-limits-Inner.sp-shadow-card {
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 6px solid #fff;
}
.sp-limits-Inner.sp-shadow-card::after {
  position: absolute;
  width: 6px;
  height: 100%;
  background: #fff;
  content: "";
  left: calc(50% - 6px);
  top: 0;
}
.sp-limits-text.sp-limits-blue {
  color: #1766FF;
}
.sp-limits-text.sp-limits-purple {
  color: #C222DC;
}
.sp-limits-text.sp-limits-blue-dr {
  color: #0C38D4;
}
.sp-limits-text.sp-limits-green {
  color: #00B150;
}
.sp-infinite {
  font-size: 24px;
  font-weight: 900;
  font-family: "DM Sans", sans-serif;
}
.sp-coming-soon {
  width: 100%;
  max-width: 65px;
}
span.sp-limits-text.sp-limits-orange {
  font-size: 15px;
  color: #FF5C00;
  font-weight: 500;
  letter-spacing: .25px;
  max-width: 120px;
  font-family: "DM Sans", sans-serif;
}
.sp-limits-items.sp-purple-gredient {
  background: linear-gradient(335deg, #fadeff, #FBFBFB);
}
.sp-limits-items.sp-blue-gredient {
  background: #EAF1FF;
}
.sp-limits-items.sp-blue-dr-gredient {
  background: #DDE4FF;
}
.sp-limits-items.sp-orange-gredient {
  background: linear-gradient(245deg, #ffe8db, #FFFBF9);
}
.sp-limits-items.sp-no-bdr,
.sp-limits-items.sp-no-bdr {
  border-bottom: none;
}
.sp-software button.nova-prospecting  , .sp-software a.nova-prospecting {
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 7px 16px;
    margin: 16px auto 0;
    border-radius: 8px;
    background: #2C73FF;
    outline: 2px solid rgb(44 115 255 / 20%);
    transition: .2s;
    letter-spacing: -.2px;
}
.sp-software button.nova-prospecting:hover , .sp-software a.nova-prospecting:hover {
    background: #1b61eb;
}
.sp-software button.nova-prospecting svg , .sp-software a.nova-prospecting svg {
  transition: .2s;
  width: 16px;
  height: 10px;
  transform: translateY(1px);
}
.sp-software button.nova-prospecting:hover svg , .sp-software a.nova-prospecting:hover svg {
  transform: translateX(5px) translateY(1px);
}
.sp-statWraper {
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  gap: 16px;
  margin-top: 8px;
}
.sp-stats-main {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgb(0 0 0 / 10%);
  margin-bottom: 16px;
  padding: 15px;
}
.sp-stats-selectWrap {
  display: flex;
  justify-content: end;
  gap: 8px;
}
.sp-stats-selectWrap {
  display: flex;
  justify-content: end;
  gap: 8px;
}
.sp-stats-selectWrap>select {
  width: max-content;
  background: #FAFBFF;
  border: 1px solid rgb(23 15 73 / 15%);
  font-size: 11px;
  font-family: "DM Sans", sans-serif;
  min-width: unset;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: -.25px;
  padding: 4px 20px 4px 8px;
  appearance: none;
  background-image: url('../../img/sp-notch.png');
  background-size: 9px;
  background-position: 94% center;
  background-repeat: no-repeat;
}
text.highcharts-axis-title {
  display: none;
} 
text.highcharts-title {
  display: none;
}
text.highcharts-subtitle {
  display: none;
}
text.highcharts-credits {
  display: none;
}
.sp-white-gredient span.sp-limits-text {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #F9F9F9;
}

.sp-limits-items.sp-white-gredient {
  background: #F9F9F9;
}

.sp-limits-items.sp-white-gredient img {
  width: 21px;
  height: 21px;
}
.sp-white-gredient span.sp-limits-text.sp-limits-orange {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  max-width: 100%;
  color: #11A052;
  font-family: "DM Sans", sans-serif;
  letter-spacing: -.25px;
}
.sp-stats-outer .sp-title {
  margin-bottom: 16px;
}


@media (min-width: 1650px) {
  .sp-dashboard-main {
    zoom: 1.2;
  }
  .sidebar-link li a.newLink span {
    font-size: 18px;
  }
  ul.sideNav>li {
    height: 49px !important;
  }
  .newLink {
    padding: 13px 15px;
  }
  .side-name-menu  p {
    font-size: 18px;
  }
  .side-name-menu svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    width: 40px;
    height: 40px;
  } 
  svg.side-drop-arrow {
    width: 40px;
    height: 40px;
  }
  .sidebar-main-parent a>img {
    width: 150px !important;
    height: 100px;
    object-fit: contain;
  }
  .sp-stats-selectWrap>select {
    font-size: 14px;
    font-weight: 500;
  }
  .sidebar-main-parent + .CmtLayout-main  .CmtLayout-content {
    padding: 0 30px !important;
  }
  .sp-dashboard-innerTop {
    margin-top: 22px;
  }
  #menu-appbar ul.MuiList-root.MuiMenu-list li {
    font-size: 18px;
  }
  ul.MuiList-root.MuiList-padding li {
    font-size: 16px;
    letter-spacing: -.25px;
  }
  #menu-appbar ul.MuiList-root.MuiMenu-list li {
    font-size: 18px;
    letter-spacing: -.25px;
  }
  #menu-appbar ul.MuiList-root.MuiMenu-list li div#customizer-select-locale span {
    font-size: 18px;
    letter-spacing: -.25px;
  }
  .ag-message-save-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    zoom: 1.25;
  }
}

@media (min-width: 1920px)  {
  .sp-dashboard-main {
    zoom: 1.4;
  }
  .sidebar-main-parent + .CmtLayout-main  .CmtLayout-content {
    padding: 0 36px !important;
}
}

@media (min-width: 2350px) {
  .sp-dashboard-main {
    zoom: 1.6;
  }
}

@media (min-width: 2650px) {
  .sp-dashboard-main {
    zoom: 2;
  }
}

@media (min-width: 3000px) {
  .sp-dashboard-main {
    zoom: 2.5;
  }
}




@media (max-width: 1100px) {
  .sp-dashboard-innerTop {
      grid-template-columns: 100%;
  }
  .sp-videoFrame {
      padding-top: 58%;
  }
  .sp-limits-items {
      min-height: 86px;
  }
  .sp-statWraper {
    grid-template-columns: 100%;
    gap: 18px;
}
}


@media (max-width: 767px) {
  .sp-social-wrapInner, .sp-videoMain {
      grid-template-columns: 100%;
      gap: 0px;
  }
}