.error-message {
    color: #e73145;
}

.profile-page {
    padding-top: 10px;
    justify-content: flex-start;
}

.profile-page-wrap {
    display: grid;
    grid-template-columns: 28% 1fr;
    gap: 35px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 25px;
}

.profile-page-left {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.profile-page-left01,
.profile-page-left02,
.profile-language {
    background: rgb(94 59 183 / 5%);
    border: 1px solid #F4EEEB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
}

.profile-page-content p {
    margin: 16px 0px;
}

.profile-page-contact a {
    color: #742f8b;
}

.profile-page-content h3 {
    margin: 18px 0px;
}

.profile-billing {
    font-size: 16px;
    color: #170F49;
    text-align: center;
    font-weight: 500;
    max-width: 250px;
    line-height: 1.5;
}

.profile-left-btn {
    background: #5E3BB7;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 26px;
    border-radius: 10px;
    outline: 1px solid rgb(94 59 183 / 30%);
    border: none;
}

.profile-page-contact {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;
}

.profile-page-content {
    text-align: center;
}

.profile-page-contact span {
    background: rgb(94 59 183 / 15%);
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.profile-page-left02 {
    border: 1px solid rgb(94 59 183 / 50%);
}

.profile-page-left02 p {
    margin: 16px 0px;
}

.profile-page-image img {
    height: auto;
    max-width: 120px;
}

.profile-language {
    flex-direction: row;
    justify-content: space-between;
}

.profile-language select {
    width: 100%;
    max-width: 74%;
    border: 1px solid rgb(36 54 74 / 15%);
    border-radius: 8px;
    height: 42px;
    padding: 10px;
    background: rgb(94 59 183 / 7%);
    margin-left: 50px;
}

.profile-selct {
    max-width: 85%;
    width: 100%;
}

.profile-password h1.profile-billing {
    text-align: left;
}

.profile-password h2.profile-billing {
    text-align: left;
    margin: 10px 0px;
}

.profile-password form {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: flex-start;
}

.prof-pass-wrap {
    max-width: 100%;
    width: 100%;
    display: flex;
    gap: 20px;
}

.prof-pass-wrap .prof-pass input {
    width: 100%;
    max-width: 100%;
    border: 1px solid rgb(36 54 74 / 15%);
    border-radius: 8px;
    height: 42px;
    padding: 10px;
    background: rgb(94 59 183 / 7%);
}

.prof-pass-wrap.prof-pass-fullwrap {
    width: 100%;
    justify-content: space-between;
}

.prof-pass {
    width: 100%;
}

.prof-pass.prof-submit-wrap {
    display: flex;
    justify-content: flex-end;
}

.prof-pass-wrap.prof-pass-halfwrap {
    width: calc(50% - 14px);
}

.prof-pass label {
    color: #475259;
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
}


.prof-submit {
    background: #41CE81;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    gap: 10px;
}

.profile-password {
    border: 1px solid #f4eeeb;
    padding: 10px 25px 25px;
    border-radius: 10px;
    margin-top: 30px;
}

@media(max-width:1024px) {
    .profile-page-wrap {
        display: grid;
        grid-template-columns: 40% 1fr;
        gap: 35px;
    }

    .prof-pass label {
        font-size: 14px;
    }
}

@media(max-width:767px) {
    .profile-page-wrap {
        grid-template-columns: 1fr;
    }

    .profile-language {
        display: block;
    }

    .profile-billing {
        margin-bottom: 15px;
        display: block;
        text-align: left;
    }

    .profile-language select {
        margin: 0;
        max-width: 100%;
    }

    .profile-selct {
        max-width: 100%;
    }

    .profile-left-btn {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .prof-pass-wrap.prof-pass-halfwrap {
        width: 100%;
    }

    .prof-pass-wrap {
        display: block;
    }

    .prof-pass-wrap>div:not(:last-child) {
        margin-bottom: 20px;
    }

    .profile-billing {
        max-width: 100%;
    }
}

.cancel-trail-wraper {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    gap: 18px;
    padding-top: 35px;
}

.cancel-trail-btn {
    background: #f73b3b;
    color: #ffff;
    border: none;
    border-radius: 10px;
    padding: 12px 27px;
    font-weight: 600;
    font-size: 16px;
    outline: 3px solid rgb(255 2 2 / 20%);
}

.cancel-trail-wraper span {
    color: #170F49;
    font-weight: 600;
    font-size: 18px;
}