* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif !important;
}
/* for future we can make this globally  */
.CmtLayout-content {
  padding: 0px !important;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
.login-main {
  background: #f8f8f8;
}
.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
}
div.grid-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 850px;
  margin: 16px auto;
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  border-radius: 20px;
  overflow: hidden;
  min-height: 550px;
  background: #fff;
}
.left-panel {
  background-image: url(../images/login-bg.png);
  background-size: cover;
  background-position: bottom center;
  height: 100%;
  padding: 35px 50px;
}
.forget-panel {
  background-image: url(../images/forget-img.png);
}
.recovery-panel {
  background-image: url(../images/recovery-img.png);
}
.logo {
  text-align: center;
}
.logo img {
  opacity: 1;
}
.title-login {
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 150%;
  margin: 24px auto;
  font-size: 18px;
}
.login-wraper {
  width: 100%;
  max-width: 390px;
  margin: 30px auto;
  padding: 0 25px;
}
.login-text {
  margin: 0 0 20px;
  font-size: 28px;
  text-align: center;
  color: #170f49;
}
.login-label {
  font-size: 16px;
  color: #475259;
  margin-bottom: 6px;
  padding-top: 5px;
  display: block;
}
.input-group {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 16px;
  border-radius: 10px;
  border: 1px solid rgb(94 59 183 / 15%);
  background: rgb(94 59 183 / 7%);
  padding: 12px 18px;
  margin-bottom: 16px;
}
.input-group input {
  background: transparent;
  border: none;
  font-size: 15px;
  color: #989898;
  font-weight: 500;
}
.input-group input::placeholder {
  font-size: 15px;
  color: #989898;
  font-weight: 500;
}
.forget-wraper {
  text-align: right;
  margin-bottom: 10px;
}
.forget-wraper a {
  font-weight: 500;
  color: #2C73FF;
  font-style: italic;
  font-size: 15px;
}
.submit-btn {
  outline: 3px solid rgb(94 59 183 / 25%);
  background: #2C73FF;
  color: #fff;
  font-size: 16px;
  padding: 14px 24px;
  border-radius: 12px;
  border: none;
  display: block;
  width: 100%;
  max-width: 160px;
  margin: 36px auto 36px;
  font-weight: 600;
  transition: 0.2s;
  cursor: pointer;
}
.submit-btn:hover {
  outline: 3px solid rgb(94 59 183 / 45%);
  background: #412291;
}
.login-bottom {
  text-align: center;
  color: #475259;
}
.login-bottom a {
  font-weight: 600;
  color: #2C73FF;
  text-decoration: none;
}
.logo img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  display: block;
}

.remember-wraper {
  color: #475259;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-start;
}
.remember-wraper label {
  color: #475259;
  font-size: 16px;
}
.remember-wraper input[type="checkbox"] {
  transform: scale(1.15);
  width: 24px;
}
.spam-text {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #828282;
}
.recovery-email-icon svg {
  width: 50px;
  height: 36px;
}
.recovery-email-icon {
  width: 90px;
  height: 90px;
  border: 2px solid #32c676;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
}
.recovery-panel-right .login-text {
  margin: 0 0 10px;
}
.recovery-panel-right .submit-btn {
  margin: 26px auto 10px;
}
.text-danger {
  position: absolute;
  bottom: -20px;
  color: red;
  font-size: 12px;
}
.none-underline {
  text-decoration: none;
}
@media (max-width: 767px) {
  div.grid-container {
    grid-template-columns: 1fr;
    max-width: 530px;
    margin: 36px auto;
  }
  .login-wraper {
    margin: 35px auto;
    padding: 0 25px;
  }
  .left-panel {
    padding: 25px;
    min-height: 390px;
  }
  .title-login {
    margin: 16px auto;
  }
}
