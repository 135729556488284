
.halloween-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgb(255 231 231);
    background-color: #cecece;
    border-radius: 3px;
}
.halloween-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background-color: #F5F5F5;
}
.halloween-scroll::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 3px;
}
.halloween-popup {
    background: rgb(0 0 0 / 65%);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.halloween-popup-inner {
    background: #fff;
    border-radius: 20px;
    width: 100%;
    max-width: 65%;
    position: relative;
    max-height: 90vh;
    overflow: hidden;
}
.halloween-popup-content {
    overflow: auto;
    height: auto;
    max-height: 90vh;
}
.halloween-popup-close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #D7D7D7;
    background: #f6f6f6;
    cursor: pointer;
    z-index: 1;
    transition: .15s;
    opacity: 1;
}
.halloween-popup-text img {
    width: 90%;
    display: block;
    margin: 24px auto;
    border-radius: 8px;
}
.halloween-btn-wraper {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 24px 0;
}
.halloween-btns {
    padding: 12px 16px;
    background: #4D70F7;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 235px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    transition: .15s;
}
.halloween-btns:hover {
    background: #3b5fe9;
}

@media (max-width: 1250px) {
    .halloween-popup-inner {
        max-width: 75%;
    }
}

@media (max-width: 1023px) {
    .halloween-popup-inner {
        max-width: 85%;
    }
    .halloween-btns {
        font-size: 14px;
    }
}


@media (max-width: 767px) {
    .halloween-popup-inner {
        max-width: 94%;
    }
    .halloween-btn-wraper {
        flex-direction: column;
        align-items: center;
    }
    .halloween-popup-text img {
        width: 100%;
        margin: 0px auto;
    }
}