.test {
  color: green;
}

.video-thumbnail {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.video-thumbnail:hover {
  opacity: 0.8;
}

.pagination-container {
  margin-top: 30px;
  display: flex;
  justify-content: center; /* Center the pagination controls horizontally */
  width: 100%; /* Ensure it takes the full width */
}

.pagination-controls {
  display: flex;
  justify-content: space-between; /* This will push the left and right items to the edges */
  width: 100%; /* Ensure it takes the full width */
}

.pagination-left,
.pagination-right {
  /* Add any specific styling for these elements if needed */
}
.main-video-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.video-frame iframe {
  width: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: scale(1.0175);
}

.video-frame {
  position: relative;
  padding-top: 57%;
}

.main-video-items {
  border-radius: 8px;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  overflow: hidden;
}

.video-title-new {
  padding: 12px 20px;
  color: #170f49;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .main-video-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .main-video-box {
    grid-template-columns: 1fr;
  }
}
